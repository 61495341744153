import React from "react";
import {FormGroup, Input, Label} from "reactstrap";

const NameForm = (props) => {
  return (
    <FormGroup>
      <Label>名前</Label>
      <Input name={'name'} innerRef={props.register} />
    </FormGroup>
  );
};

export default NameForm;
