// const calcAge = () => {
//
// };

const calcAge = (birthday) => {
  if (birthday === null || birthday === '') {
    return "";
  }
  let bArr = birthday.split('-');
  let birth = new Date(bArr[0], (bArr[1] - 1), bArr[2]); // 誕生日の日付
  let today = new Date(); // 今日の日付
  let df = today - birth; // 今日と誕生日の差(ミリ秒)
  let age = Math.floor(df / 1000 / 60 / 60 / 24 / 365); // ミリ秒を年に変換(切り捨て)
  return age
};

export default calcAge;
