import React from "react";
import {Button, Card, CardBody, CardFooter, Form} from "reactstrap";
import InfluencerTalentNameForm from "./influencer_talent_name_form";
import CompanyNameForm from "./company_name_form";
import GenderForm from "./gender_form";
import GenderRatioForm from "./gender_ratio_form";
import AgeRangeForm from "./age_range_form";
import CostForm from "./cost_form";
import ChannelRegisterCountForm from "./channel_resister_count_form";
import {useForm} from "react-hook-form";

const SearchConditionsForm = (props) => {
  console.log(props);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: '',
      gender_ratio: '',
      age_range: [{}],
      cost: [{}],
      channel_register_count: [{}],
      company_name: '',
      gender: '',
    }
  });

  return (
    <Card>
      <Form onSubmit={handleSubmit(props.onSubmit)}>
        <CardBody>
          <InfluencerTalentNameForm register={register} />
          <CompanyNameForm register={register} />
          <GenderForm register={register} />
          <GenderRatioForm register={register} />
          <AgeRangeForm register={register} />
          <CostForm register={register} />
          <ChannelRegisterCountForm register={register} />
        </CardBody>
        <CardFooter>
          <Button type="submit" color={'primary'}>検索する</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default SearchConditionsForm;
