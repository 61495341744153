export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'タレント',
      url: '/talents',
      icon: 'icon-docs',
      children: [
        {
          name: '検索',
          url: '/talents/list',
          icon: 'icon-puzzle',
        },
        {
          name: '新規作成',
          url: '/talents/new',
          icon: 'icon-puzzle',
        }
      ]
    },
  ]
};
