import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, Label} from "reactstrap";
import PastProposalDocumentContainer from "../../../components/proposal_documents/past_proposal_document_container";

const Index = () => {
  const [talent_name, set_talent_name] = useState("");
  const { register, handleSubmit } = useForm({
    defaultValues: {
      talent_name: ''
    }
  });
  const onSubmit = (data) => {
    set_talent_name(data.talent_name)
  };

  return (
    <>
      <Card>
        <CardHeader>
          <i className="icon-menu"/>過去リスト検索{' '}
        </CardHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <FormGroup>
              <Label>タレント名</Label>
              <Input type='text' id={'talent_name'} name={'talent_name'} innerRef={register} />
            </FormGroup>
          </CardBody>
          <CardFooter className={'text-right'}>
            <Button type={'submit'} color={'primary'}>検索</Button>
          </CardFooter>
        </Form>
      </Card>

      <PastProposalDocumentContainer talent_name={talent_name} />
    </>
  );
};

export default Index;
