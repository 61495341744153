import {Badge, FormGroup, Input, Label} from "reactstrap";
import React from "react";

const NameForm = (props) => {
  let badge = <></>;
  if(props.required) badge = <Badge color="danger">必須</Badge>;

  return (
    <FormGroup>
      <Label>名前 {badge}</Label>
      <Input name="name"
             placeholder={''}
             innerRef={props.register({required: "名前が入力されていません。"})}
             defaultValue={props.value}
      />
      { props.errors.name && <span style={style.errorMsg}>{ props.errors.name.message }</span> }
    </FormGroup>
  );
};

const style = {
  errorMsg: {
    color: 'red'
  }
};

export default NameForm;
