import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const ProfileForm = (props) => {
  return (
    <FormGroup>
      <Label>プロフィール</Label>
      <Input type="textarea" id={'profile'} name={'profile'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default ProfileForm;
