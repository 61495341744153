import React, {useState} from 'react';
import DoneProposalDocumentPaginationTable
  from "../../../components/proposal_documents/done_proposal_document_pagination_table";
import {Card, CardBody, CardHeader, Button} from "reactstrap";
import {Link} from "react-router-dom";
import {useMutation, useQuery} from "@apollo/react-hooks";
import FETCH_PROPOSAL_DOCUMENTS_QUERY from "../../../graphql/queries/proposal_documents_query";
import DELETE_DOCUMENT_QUERY from "../../../graphql/mutations/delete_proposal_document_mutation";

const Index = (props) => {
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortType] = useState('talents.name');
  const [sortOrder] = useState('desc');
  const [page, setPage] = useState(0);

  const [delete_proposal_document] = useMutation(DELETE_DOCUMENT_QUERY);
  const {loading, error, data} = useQuery(FETCH_PROPOSAL_DOCUMENTS_QUERY, {variables: {
      q: {
        account_id_eq: localStorage.getItem("account_id"),
        export_excel_status_eq: 1
      },
      limit: limit,
      offset: offset,
      sort_type: sortType,
      sort_order: sortOrder,
  }});
  if(loading) return "...";
  if(error) return "エラーが発生しています。";

  let proposal_documents = data.proposalDocuments;
  let table_data = proposal_documents.edges.map(edge => edge.node);

  const columns = [
    {header: '名前', accessor: 'name', Cell: cell => { return (<Link to={'/proposal_documents/'+cell.row.original.id+'/edit'}>{cell.row.original.name}</Link>) }},
    {header: '-', accessor: '-', Cell: cell => { return (<Button onClick={() => handle_delete_proposal_document(cell.row.original.id, cell.row.original.name)}>削除</Button>) }}
  ];

  const handle_delete_proposal_document = (id, name) => {
    delete_proposal_document({variables: {id: parseInt(id)}})
      .then(result => {
        alert(`『${name}』が削除されました`);
        window.location.reload();
      })
      .catch(error => {
        alert('エラーが発生しました'+error);
      });
  };

  const handle_fetch_data = (index, size) => {
    setPage(index);
    setOffset(index*size);
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>DL済リスト{' '}</CardHeader>
        <CardBody>
          <DoneProposalDocumentPaginationTable
            data={table_data}
            columns={columns}
            page={page}
            total_size={Math.ceil(data.proposalDocuments.totalCount/limit)}
            fetch_data={handle_fetch_data} />
        </CardBody>
      </Card>
    </>
  );
};

export default Index;
