import React from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Form, Progress} from "reactstrap";
import {useDropzone} from "react-dropzone";
import TalentCsvPaginationTable from "../../components/talents/talent_csv_pagination_table";
import {useQuery} from "@apollo/react-hooks";
import FETCH_TALENT_CSV_IMPORT_LOG_QUERY from "../../graphql/queries/talent_csv_import_log_query";

const CsvImport = (props) => {
  const {getRootProps, getInputProps} = useDropzone();
  const {loading, error, data} = useQuery(FETCH_TALENT_CSV_IMPORT_LOG_QUERY, {variables: {
      q: {
        account_id_eq: localStorage.getItem("account_id"),
      },
      limit: 10,
      offset: 0,
  }});
  if(loading) return "...";
  if(error) return "エラーが発生しています。";

  let talent_csv_import_logs = data.talentCsvImportLogs;
  let table_data =talent_csv_import_logs.edges.map(talent_csv_import_log => talent_csv_import_log.node);

  const uploadFile = (props) => {
  };

  const handleFetchData = () => {
  };

  const columns = [
    {Header: 'ID', accessor: 'id'},
    {Header: 'タレント数', accessor: 'talent_count', Cell: cell => { return (<span>{cell.row.original.talentCount}人</span>) }},
    {Header: '進捗', accessor: 'progress', Cell: cell => { return (
        <>
          <div className="text-center">{cell.row.original.progress}%</div>
          <Progress color="info" value={cell.row.original.progress} />
        </>
    )}},
    {Header: 'ダウンロードリンク', accessor: 'csvFile', Cell: cell => { return (<Button onClick={() => {}} color={'primary'}>ダウンロード</Button>  ) }},
  ];

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>タレントCSV読み込み{' '}</CardHeader>
        <Form>
          <CardBody>
            <div {...getRootProps({style: style.dropzone})}>
              <input {...getInputProps()} accept={'.csv'} />
              <p>ここをクリックまたはCSVをドラッグ＆ドロップしてください。</p>
            </div>
          </CardBody>
          <CardFooter className={'text-right'}>
            <button className={'btn btn-primary'} onClick={() => uploadFile()}>アップロード</button>
          </CardFooter>
        </Form>
      </Card>
      <Card>
        <CardHeader><i className="icon-menu"/>タレントCSV一覧{' '}</CardHeader>
        <CardBody>
          <TalentCsvPaginationTable
            data={table_data}
            columns={columns}
            total_size={Math.ceil(talent_csv_import_logs.totalCount/10)}
            fetch_data={handleFetchData} />
        </CardBody>
      </Card>
    </>
  );
};

const style = {
  dropzone: {
    padding: '2rem 1rem 1rem 1rem',
    backgroundColor: '#ccc',
    textAlign: 'center',
    color: '#666'
  }
};

export default CsvImport;
