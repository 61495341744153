import React, {useState} from 'react';
import UserPaginationTable from "../../components/users/user_pagination_table";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import FETCH_ACCOUNTS_QUERY from "../../graphql/queries/accounts_query";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {useHistory} from "react-router-dom";
import DELETE_ACCOUNT_QUERY from "../../graphql/mutations/delete_account_mutation";

const Index = (props) => {
  const history = useHistory();
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const [deleteAccount] = useMutation(DELETE_ACCOUNT_QUERY);

  const {loading, error, data} = useQuery(FETCH_ACCOUNTS_QUERY, {variables: {
    q: {},
    limit: limit,
    offset: offset,
  }});
  if(loading) return "...";
  if(error) return "エラーが発生しています。"+error;

  let table_data = data.accounts.edges.map(account => account.node);

  const handle_fetch_data = (index, size) => {
    setPage(index);
    setOffset(index*size);
  };

  const handle_delete_data = (id) => {
    if(parseInt(localStorage.getItem('account_id')) === parseInt(id)) return alert("自分のIDは削除できません。")
    deleteAccount({variables: {id: id}})
      .then(result => {
        alert(`『ID: ${id}』は削除されました。`);
        history.go(0);
    }).catch(error => {
      alert(`『ID: ${id}』は削除に失敗しました。${error}`);
    });
  };

  const columns = [
    {id: 'id', header: 'ID', accessor: 'id', Cell: cell => { return (<span>{cell.row.original.id}</span>) }},
    {id: 'role', header: '権限', accessor: 'role', Cell: cell => { return (<span>{cell.row.original.role}</span>) }},
    {id: 'email', header: 'メールアドレス', accessor: 'email', Cell: cell => { return (<span>{cell.row.original.email}</span>) }},
    {id: '-', header: '-', accessor: '', Cell: cell => { return (
      <>
        {/*<Button color={'warning'} onClick={() => handleUpdateDate(cell.row.original.id)}>編集</Button>*/}
        <Button color={'danger'} onClick={() => handle_delete_data(cell.row.original.id)}>削除</Button>
      </>
    )}}
  ];

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>ユーザー一覧{' '}</CardHeader>
        <CardBody>
          <UserPaginationTable
            data={table_data}
            columns={columns}
            page={page}
            total_size={Math.ceil(data.accounts.totalCount/limit)}
            fetch_data={handle_fetch_data} />
        </CardBody>
      </Card>
    </>
  );
};

export default Index;
