import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Nav,
  UncontrolledDropdown
} from 'reactstrap';
import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import logo from '../assets/img/brand/logo.png'
import sygnet from '../assets/img/brand/sygnet.svg'
import avatar from '../assets/img/avatars/6.jpg'

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

const DefaultHeader = (props) => {
  const history = useHistory();
  // eslint-disable-next-line
  const { children, ...attributes } = props;

  return (
    <React.Fragment>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <Link to={'/proposal_documents/new'}>
        <AppNavbarBrand full={{ src: logo, width: 73.1, height: 53.5, alt: 'A3 Logo' }} minimized={{ src: sygnet, width: 36.55, height: 26.75, alt: 'A3 Logo' }} />
      </Link>
      {/*<AppSidebarToggler className="d-md-down-none" display="lg" />*/}
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav direction="down">
          <DropdownToggle nav>
            <img src={avatar} className="img-avatar" alt="admin@bootstrapmaster.com" />
          </DropdownToggle>
          <DropdownMenu right style={{ height: '400px', right: 0 }}>
            <DropdownItem>
              <ListGroup flush>
                <ListGroupItem tag="button" action onClick={() => {
                  localStorage.clear();
                  history.push("/login");
                }}>ログアウト</ListGroupItem>
              </ListGroup>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
      {/*<AppAsideToggler className="d-md-down-none" />*/}
    </React.Fragment>
  );
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
