import {Badge, FormGroup, Input, Label} from "reactstrap";
import React from "react";

const GenderForm = (props) => {
  let badge = <></>;
  if(props.isMutation) badge = <Badge color="danger">必須</Badge>;

  return (
    <FormGroup>
      <Label>性別 {badge}</Label>
      <Input type="select" id={'gender'} name={'gender'} innerRef={props.register} defaultValue={props.value}>
        <option value="" selected="selected">--選択してください--</option>
        <option value="男性">男性</option>
        <option value="女性">女性</option>
        <option value="それ以外">それ以外</option>
      </Input>
    </FormGroup>
  );
};

export default GenderForm;
