import gql from "graphql-tag";

const ADD_DOCUMENT_QUERY = gql`
mutation createProposalDocument($account_id: Int!, $name: String!){
    createProposalDocument(input: {
      accountId: $account_id
      name: $name
    }){
      success
      proposalDocument{
        id
        name
      }
    }
}`;

export default ADD_DOCUMENT_QUERY;
