import React from 'react';
import {Card, CardHeader} from "reactstrap";
import {useQuery} from "react-apollo";
import FETCH_TALENT_QUERY from "../../graphql/queries/talent_query";
import TalentEditForm from "../../components/talents/talent_edit_form";

const Edit = (props) => {
  let id = props.match.params.id;
  const {loading, error, data} = useQuery(FETCH_TALENT_QUERY, { fetchPolicy: "network-only" , variables: { id: id } });
  if(loading) return "...";
  if(error) return "エラーが発生しました";

  let talent_images = [];
  for(let i in data.talent?.talentImages){
    let talent_image = data.talent?.talentImages[i];
    talent_images.push({url: talent_image.originalImageUrl, file: '', category: convert_image_category(talent_image.imageCategory)})
  }

  return (
    <>
      <Card>
        <CardHeader>
          <i className="icon-menu"/>タレント更新{' '}
        </CardHeader>
        <TalentEditForm id={id} talent={data.talent} talent_images={talent_images} />
      </Card>
    </>
  );
};

const convert_image_category = (str_image_category) => {
  let image_category = 1;
  if(str_image_category === 'bust_up'){
    image_category = 1;
  }else if(str_image_category === 'face_up') {
    image_category = 2;
  }else if(str_image_category === 'sns'){
    image_category = 3;
  }else{
    image_category = 99;
  }
  return image_category;
};


export default Edit;
