import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const InfluencerTalentNameForm = (props) => {
  return (
    <FormGroup>      
      <Label>タレント名・グループ名</Label>
      <Input name="name" innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default InfluencerTalentNameForm;
