import React from "react";
import RoundedImage from "../shared/rounded_image";

const TalentPoolRoundedImage = (props) => {
  let image_url = "";

  if(props.proposal_document_item.talentImage === null) return (<RoundedImage image_url={image_url} />);

  if(process.env.NODE_ENV === 'development') { image_url = process.env.REACT_APP_IMAGE_ENDPOINT; }

  if(props.talent_image_id === null){
    image_url += props.proposal_document_item.talentImage.imageUrl
  }else{
    image_url += props.proposal_document_item.talent.talentImages.filter((talentImage, index) => { return parseInt(talentImage.id) === parseInt(props.talent_image_id) })[0].imageUrl;
  }

  return (
    <RoundedImage image_url={image_url} />
  );
};

export default TalentPoolRoundedImage;
