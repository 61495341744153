import {FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import FETCH_INFLUENCER_TALENT_COMPANIES_QUERY from "../../graphql/queries/influencer_talent_company_query";

const CompanyNameForm = (props) => {
  const {loading, error, data} = useQuery(FETCH_INFLUENCER_TALENT_COMPANIES_QUERY);
  if(loading) return "...";
  if(error) return "エラーが発生しました。"+error;

  let company_name = data.influencerTalentCompanies.map(company => (
    <option value={company['name']}>{company['name']}</option>
  ));

  return (
    <FormGroup>
      <Label>所属事務所名</Label>
      <Input type="select" id={'company_name'} name={'company_name'} innerRef={props.register} defaultValue={props.value}>
        <option value="" selected="selected">--選択してください--</option>
        {company_name}
      </Input>
    </FormGroup>
  );
};

export default CompanyNameForm;
