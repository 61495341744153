import React from 'react'
import { Input, Label } from 'reactstrap'

const RadioBoxButton = (props) => {
  let className = "btn"
  if(props.nonePrimary === true) className += " btn-primary"
  return (
    <>
      <Label check for={props.id} className={className}>
        <Input type={'radio'} autocomplete={'off'} innerRef={props.innerRef} id={props.id} name={props.name} value={props.value} checked={props.checked} />{props.label}
      </Label>
    </>
  )
}

export default RadioBoxButton
