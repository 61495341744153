import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const YearPriceForm = (props) => {
  return (
    <FormGroup>
      <Label>想定コスト</Label>
      <Input type='text' id={'year_price'} name={'year_price'} innerRef={props.register} defaultValue={props.value ? props.value : 0} />
    </FormGroup>
  );
};

export default YearPriceForm;
