import {FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import FETCH_TALENT_TERTIARY_CATEGORY_QUERY from "../../graphql/queries/talent_tertiary_category_query";

const CategoryTertiaryItemForm = (props) => {
  const {loading, data} = useQuery(FETCH_TALENT_TERTIARY_CATEGORY_QUERY, { variables: { talent_secondary_category_name: props.category_secondary } });
  if(loading) return "Loading...";

  return (
    <FormGroup>
      <Label>小カテゴリー</Label>
      <Input type="select" name={'categories.tertiary'} innerRef={props.register} defaultValue={props.value} >
        <option value="" selected="selected">--選択してください--</option>
        {data?.talentTertiaryCategories?.map(category => (
          <option value={category.name}>{category.name}</option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default CategoryTertiaryItemForm;
