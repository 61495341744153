import React, {useCallback, useState} from "react";
import {useDropzone} from "react-dropzone";

const ImageDropdownForm = (props) => {
  const [base64_file, set_base64_file] = useState("");

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    console.log(acceptedFiles);
    acceptedFiles.map(file => {
      const reader = new FileReader();
      reader.onload = (e) => {
        let base64_data = e.target.result;
        set_base64_file(base64_data);
      }
      reader.readAsDataURL(file);
      return null;
    });
  }, []);

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  return (
    <>
      <div {...getRootProps({style: style.dropzone})}>
        <input {...getInputProps()} accept={['.png', '.jpg', '.jpeg', '.gif']} />
        <p>ここをクリックまたは画像をドラッグ＆ドロップしてください。</p>
      </div>
      <input type={'hidden'} name={props.name} value={base64_file} ref={props.innerRef} />
      {base64_file &&
          <img src={base64_file}  alt={''} style={{maxWidth: 512, maxHeight: 512}} />
      }
    </>
  );
};

const style = {
  dropzone: {
    padding: '2rem 1rem 1rem 1rem',
    backgroundColor: '#ccc',
    textAlign: 'center',
    color: '#666'
  }
};

export default ImageDropdownForm;
