import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const GroupForm = (props) => {
  return (
    <FormGroup>
      <Label>所属グループ</Label>
      <Input type="text" id='group' name={'group'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default GroupForm;
