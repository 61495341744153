import gql from "graphql-tag";

const INFLUENCER_FETCH_TALENT_QUERY = gql`
query influencerTalents($q: BaseScalar, $limit: Int, $offset: Int, $sort_type: String!, $sort_order: String!) {
  influencerTalents(q: $q, limit: $limit, offset: $offset, sortType: $sort_type, sortOrder: $sort_order){
    totalCount
    edges {
      node {
        id
        name
        gender
        cost
        youtubeUrl
        channelRegisterCount
        channelAveragePlayCount
        channelExpectedPlayCount
        period
        createdAt
        updatedAt
        influencerTalentCompany{
          id
          name
          homepageUrl
          createdAt
          updatedAt
        }
        influencerTalentImages{
          id
          imageCategory
          imageUrl
          originalImageUrl
          createdAt
          updatedAt
        }
        influencerTalentGenderRatio{
          id
          manRatio
          womanRatio
        }
        influencerTalentAgeRanges{
          id
          ratio
          order
          ageRange{
            id
            name
          }
        }
      }
    }
  }
}`;

export default INFLUENCER_FETCH_TALENT_QUERY;
