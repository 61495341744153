import React from "react";
import {Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";

const SearchNameForm = (props) => {
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: ''
    }
  });
  const onSubmit = (data) => {
    props.setIsSearch(true);
    props.setName(data.name);
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <Row>
            <Col>
              <FormGroup row>
                <Col md={2}><Label htmlFor="name">タレント名・グループ名</Label></Col>
                <Col md={10}><Input name="name" innerRef={register} /></Col>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <button type="submit" className='btn btn-primary'>検索する</button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default SearchNameForm;
