import React, {useState} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader, Col,
  Form, FormGroup, Input, Label
} from "reactstrap";
import {useMutation, useQuery} from "@apollo/react-hooks";
import FETCH_PROPOSAL_DOCUMENT_QUERY from "../../graphql/queries/proposal_document_query";
import TalentSearchContainer from "../../components/talents/talent_search_container";
import TalentPool from "../../components/proposal_documents/talent_pool";
import ADD_DOCUMENT_ITEM_QUERY from "../../graphql/mutations/proposal_document_item_mutation";
import DELETE_DOCUMENT_ITEM_QUERY from "../../graphql/mutations/delete_proposal_document_item_mutation";
import UPDATE_ORDER_DOCUMENT_ITEM_QUERY from '../../graphql/mutations/update_proposal_document_item_mutation'
import {useForm} from "react-hook-form";
import axios from "axios";
import moment from "moment";

const excel_header_items = [
  [
    {header: '名前', accessor: 'name'},
    {header: 'ニックネーム', accessor: 'nickname'},
    {header: '画像', accessor: 'image'},
    {header: '性別', accessor: 'gender'},
    {header: '誕生日', accessor: 'birthday'},
    {header: '年齢', accessor: 'age'},
    {header: 'グループ', accessor: 'group'},
    {header: '大カテゴリ', accessor: 'category_primary_item', explain: '例）芸能'},
    {header: '中カテゴリ', accessor: 'category_secondary_item', explain: '例）タレント、モデル'},
    {header: '小カテゴリ', accessor: 'category_tertiary_item', explain: '例）モデル'},
  ],
  [
    {header: '所属事務所', accessor: 'company_name'},
    {header: '所属事務所URL', accessor: 'homepage_url'},
    {header: '出身地', accessor: 'native_place'},
    {header: '想定コスト', accessor: 'year_price'},
  ],
  [
    {header: 'BLOGのURL', accessor: 'blog_url'},
    {header: 'BLOGの読者数', accessor: 'blog_reader'},
    {header: 'TwitterのURL', accessor: 'twitter_url'},
    {header: 'Twitterフォロワー数', accessor: 'twitter_follower'},
    {header: 'InstagramのURL', accessor: 'instagram_url'},
    {header: 'Instagramフォロワー数', accessor: 'instagram_follower'},
    {header: 'YoutubeのURL', accessor: 'youtube_url'},
    {header: 'Youtubeの登録者数', accessor: 'youtube_subscriber_count'},
    {header: 'Youtubeの視聴者数', accessor: 'youtube_viewer_count'},
    {header: 'プロフィール', accessor: 'profile'},
    {header: 'おすすめポイント', accessor: 'recommend_point'},
  ],
];

const Edit = (props) => {
  const id = parseInt(props.match.params.id);
  const [talent_pool_data, set_talent_pool_data] = useState({});
  const { register, handleSubmit } = useForm({defaultValues: {}});

  const [add_talent_pool] = useMutation(ADD_DOCUMENT_ITEM_QUERY);
  const [delete_talent_pool] = useMutation(DELETE_DOCUMENT_ITEM_QUERY);
  const [update_talent_pool] = useMutation(UPDATE_ORDER_DOCUMENT_ITEM_QUERY);
  const {loading, error, data} = useQuery(FETCH_PROPOSAL_DOCUMENT_QUERY, {variables: {id: id, account_id: parseInt(localStorage.getItem("account_id"))}});
  if(loading) return "...";
  if(error) return "エラーが発生しています。" + error;

  let proposal_document = data.proposalDocument;
  let proposal_document_items_on_server = [];
  proposal_document.proposalDocumentItems.map(proposal_document_item => proposal_document_items_on_server.push(proposal_document_item));
  if(Object.keys(talent_pool_data).length === 0) set_talent_pool_data({ proposal_document_items: proposal_document_items_on_server });

  const handleAddTalentPool = (talent) => {
    let talent_order = talent_pool_data.proposal_document_items.length+1
    // DBに追加
    add_talent_pool({variables: {id: id, talent_id: parseInt(talent.id), talent_image_id: 0, talent_order: talent_order}})
      .then(result => {
        let receive_data = result.data.createProposalDocumentItem.proposalDocumentItems
        set_talent_pool_data({ proposal_document_items: receive_data });
        alert(`『${talent.name}』を追加しました。`)
    }).catch(error => {
      alert('エラーが発生しました。'+error)
    });
  };

  const handleDeleteTalentPool = (talent) => {
    // DBから削除(名前で検索)
    delete_talent_pool({variables: {id: id, talent_id: parseInt(talent.id)}})
      .then(result => {
        let receive_data = result.data.deleteProposalDocumentItem.proposalDocumentItems;
        set_talent_pool_data({ proposal_document_items: receive_data });
        alert(`『${talent.name}』を削除しました。`)
    }).catch(error => {
        alert('エラーが発生しました。'+error)
    });
  };

  const handlePrevTalentPool = (talent) => {
    let proposal_document_items = proposal_document.proposalDocumentItems.filter(pdi => parseInt(pdi.talentId) === parseInt(talent.id))
    let proposal_document_item = proposal_document_items[0]
    let talent_order = parseInt(proposal_document_item.talentOrder)
    if(talent_order > 1) {
      update_talent_pool({variables: {id: id, talent_id: parseInt(talent.id), talent_image_id: 0, talent_order: talent_order-1}})
        .then(result => {
          let receive_data = result.data.updateProposalDocumentItem.proposalDocumentItems;
          set_talent_pool_data({ proposal_document_items: receive_data });
      }).catch(error => {
        alert('エラーが発生しました。'+error)
      });
    }
  };

  const handleNextTalentPool = (talent) => {
    let proposal_document_items = proposal_document.proposalDocumentItems.filter(pdi => parseInt(pdi.talentId) === parseInt(talent.id))
    let proposal_document_item = proposal_document_items[0]
    let talent_order = parseInt(proposal_document_item.talentOrder)
    if(proposal_document.proposalDocumentItems.length >= talent_order+1){
      update_talent_pool({variables: {id: id, talent_id: parseInt(talent.id), talent_image_id: 0, talent_order: talent_order+1}})
        .then(result => {
          let receive_data = result.data.updateProposalDocumentItem.proposalDocumentItems;
          set_talent_pool_data({ proposal_document_items: receive_data });
      }).catch(error => {
        alert('エラーが発生しました。'+error)
      });
    }
  };

  const onSubmit = (data) => {
    let params = new URLSearchParams();
    params.append('excel_target_name', data.excel_target_name);
    params.append('excel_project_name', data.excel_project_name);
    Object.keys(data.excel_items).map(key => params.append(`excel_items[${key}]`, Boolean(data.excel_items[key])));
    axios.post(
      `${process.env.REACT_APP_DOCUMENT_ENDPOINT}/proposal_documents/${id}`,
      params,
      {
        responseType: "blob"
      }
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      let today = moment();
      let today_str = today.format('MMDD')
      link.setAttribute('download', `【A3】${today_str}_${data.excel_target_name}様_${data.excel_project_name}ご提案資料.xlsx`); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardHeader><i className="icon-menu"/>リストダウンロード{' - '}{proposal_document.name}</CardHeader>
            <CardBody>
              <FormGroup row>
                <Col md={2}><Label for={'excel_target_name'}>宛先名</Label></Col>
                <Col md={10}>
                  <Input type={'text'} id={'excel_target_name'} name={'excel_target_name'} required innerRef={register} />
                  <p>例) ここに「〇〇」と入力するとエクセルのタイトルが「20200503_〇〇様_□□□_ご提案資料.xlsx」となります。</p>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={2}><Label for={'excel_project_name'}>案件名</Label></Col>
                <Col md={10}>
                  <Input type={'text'} id={'excel_project_name'} name={'excel_project_name'} required innerRef={register}/>
                  <p>例) ここに「□□□」と入力するとエクセルのタイトルが「20200503_〇〇様_□□□_ご提案資料.xlsx」となります。</p>
                </Col>
              </FormGroup>

              <FormGroup row>
                <Col md={2}>
                  <Label>エクセルに出力する項目</Label>
                </Col>
                <Col md={10}>
                  {excel_header_items.map(items => {
                    return (
                        items.map(excel_header_item => {
                          return (
                            <FormGroup check inline>
                              <Label for={excel_header_item.accessor} check>
                                <Input type={'checkbox'} id={excel_header_item.accessor} name={`excel_items[${excel_header_item.accessor}]`} defaultChecked={true} innerRef={register} />
                                {excel_header_item.header}
                                <br />
                                <p style={style.excel_item_explain}>{excel_header_item.explain}</p>
                              </Label>
                            </FormGroup>
                          )
                        })
                    )
                  })}
                </Col>
              </FormGroup>
            </CardBody>
            <CardFooter className={'text-right'}>
              <Button type={'submit'} color={'primary'}>Excel ダウンロード</Button>
            </CardFooter>
          </Form>
        </Card>

        <TalentPool proposal_document_items={talent_pool_data.proposal_document_items}
                    handleDeleteTalentPool={handleDeleteTalentPool}
                    handlePrevTalentPool={handlePrevTalentPool}
                    handleNextTalentPool={handleNextTalentPool} />

        <TalentSearchContainer isEditPage={true} handleAddTalentPool={handleAddTalentPool} />
    </>
  );
};

const style = {
  excel_item_explain: {
    fontSize: '12px'
  },
};

export default Edit;
