import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import {AppAside, AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer, AppSidebarNav,} from '@coreui/react';
import no_role_navigation from '../navigation/_no_role';
import super_user_navigation from '../navigation/_super_user';
import manager_navigation from '../navigation/_manager';
import staff_navigation from '../navigation/_staff';
import worker_navigation from '../navigation/_worker';
import routes from '../routes';
import DefaultHeader from "./DefaultHeader";
import DefaultAside from "./DefaultAside";
import DefaultFooter from "./DefaultFooter";
import {useQuery} from "@apollo/react-hooks";
import {useHistory} from 'react-router-dom';
import FETCH_ACCOUNT_QUERY from "../graphql/queries/account_query";

const DefaultLayout = (props) => {
  const history = useHistory();
  let uid = localStorage.getItem("uid");
  const {loading, error, data} = useQuery(FETCH_ACCOUNT_QUERY, {variables: {uid: uid}});
  if(loading) return "...";
  if(error) return "エラーが発生しました。"+error;
  if(uid === null || uid === undefined) history.push('/login');

  localStorage.setItem("account_id", data.account.id);

  let role = data.account.role;
  console.log(role);
  let navigation = no_role_navigation;
  if(role === "super_user"){
    navigation = super_user_navigation;
  }else if(role === "manager"){
    navigation = manager_navigation;
  }else if(role === "staff"){
    navigation = staff_navigation;
  }else if(role === "worker"){
    navigation = worker_navigation;
  }

  return (
    <div className="app">
      <AppHeader fixed><DefaultHeader /></AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <AppSidebarNav navConfig={navigation} {...props} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={routes}/>
          <Container fluid>
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (<route.component {...props} />)} />) : (null);
              })}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </Container>
        </main>
        <AppAside fixed>
          <DefaultAside />
        </AppAside>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
};

export default DefaultLayout;
