import gql from "graphql-tag";

const FETCH_TALENT_QUERY = gql`
query($id: ID!){
  talent(id: $id){
    id
    name
    gender
    birthday
    yearPrice
    nativePlace
    homepageUrl
    instagramAccount
    twitterAccount
    youtubeAccount
    weiboAccount
    blogUrl
    profile
    createdAt
    updatedAt
    talentPrimaryCategory{
      id
      name
    }
    talentSecondaryCategory{
      id
      name
    }
    talentTertiaryCategory{
      id
      name
    }
    talentCompany{
      id
      name
      createdAt
      updatedAt
    }
    talentGroup{
      id
      name
      nameKana
      createdAt
      updatedAt
    }
    talentNicknames{
      id
      name
      nameKanaFirst
      nameKanaLast
      createdAt
      updatedAt
    }
    talentNotes{
      id
      title
      contents
      createdAt
      updatedAt
    }
    talentImages{
      id
      imageCategory
      imageUrl
      originalImageUrl
      createdAt
      updatedAt
    }
    talentTwitterCounts{
      id
      followCount
      followedCount
    }
    talentYoutubeCounts{
      id
      subscriberCount
      viewerCount
    }
    talentInstagramCounts{
      id
      followCount
      followedCount
      postCount
    }
  }
}`;

export default FETCH_TALENT_QUERY;
