import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const BlogAccountForm = (props) => {
  return (
    <FormGroup>
      <Label>Blogアカウント</Label>
      <Input type="text" id={'blog_account'} name={'blog_account'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default BlogAccountForm;
