import React from "react";
import InfluencerTalentPoolRoundedImage from "./talent_pool_rounded_image";

const SelectRoundedImage = (props) => {
  return (
    <>
      <InfluencerTalentPoolRoundedImage influencer_talent_proposal_document_item={props.influencer_talent_proposal_document_item} />
    </>
  );
};

export default SelectRoundedImage;
