import {FormGroup, Label} from "reactstrap";
import React from "react";
import RadioBoxButton from "../shared/radiobox_button";

const GenderRatioForm = (props) => {
  return (
    <>
      <FormGroup>
        <Label for={"gender_ratio"} style={{fontWeight: 800}}>■ 視聴者男女比</Label>
      </FormGroup>
      <FormGroup>
        <FormGroup check inline>
          <RadioBoxButton
            id={`gender-ratio-0`}
            name={"gender_ratio"}
            label={"条件なし"}
            value={0}
            innerRef={props.register}
          />
        </FormGroup>
        <FormGroup check inline>
          <RadioBoxButton
            id={`gender-ratio-1`}
            name={"gender_ratio"}
            label={"男性８割以上（男性の視聴者層が80%以上）"}
            value={1}
            innerRef={props.register}
          />
        </FormGroup>
        <FormGroup check inline>
          <RadioBoxButton
            id={`gender-ratio-2`}
            name={"gender_ratio"}
            label={"女性８割以上（女性の視聴者層が80%以上）"}
            value={2}
            innerRef={props.register}
          />
        </FormGroup>
        <FormGroup check inline>
          <RadioBoxButton
            id={`gender-ratio-3`}
            name={"gender_ratio"}
            label={"どっちにも人気"}
            value={3}
            innerRef={props.register}
          />
        </FormGroup>
      </FormGroup>
    </>
  );
};

export default GenderRatioForm;
