import React, {useState} from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import SearchConditionsForm from "./influencer_talent_search_conditions_form";
import InfuluencerTalentSearchResult from "./influencer_talent_search_result";

const InfluencerTalentSearchContainer = (props) => {
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [gender, setGender] = useState('');
  const [genderRatio, setGenderRatio] = useState('');
  const [ageRange, setAgeRange] = useState([]);
  const [costGt, setCostGt] = useState([]);
  const [costLt, setCostLt] = useState([]);
  const [channelRegisterCountGt, setChannelRegisterCountGt] = useState([]);
  const [channelRegisterCountLt, setChannelRegisterCountLt] = useState([]);
  const [isSearch, setIsSearch] = useState(false);

  const onSubmit = (data) => {
    console.log(data)
    setIsSearch(true);
    setName(data.name);
    setCompany(data.company_name);
    setGender(data.gender);
    setGenderRatio(data.gender_ratio);
    setAgeRange(data.age_range);

    let cost_gt = data.cost.map(c => {
      let return_value = undefined
      if(parseInt(c) === 1) return_value = 0
      if(parseInt(c) === 2) return_value = 510000
      if(parseInt(c) >= 3) return_value = ((c-2)*100+1) * 10000
      return return_value
    })
    let cost_lt = data.cost.map(c => {
      let return_value = undefined
      if(parseInt(c) === 1) return_value = 500000
      if(parseInt(c) >= 2 && parseInt(c) <= 6) return_value = ((parseInt(c)-1) * 100) * 10000
      return return_value
    })
    setCostGt(cost_gt);
    setCostLt(cost_lt);

    let channel_register_count_gt = data.channel_register_count.map(crc => {
      let return_value = undefined
      if(parseInt(crc) === 1) return_value = 10000
      if(parseInt(crc) === 2) return_value = 50000
      if(parseInt(crc) >= 3 && parseInt(crc) < 8) return_value = (parseInt(crc)-2) * 100000
      if(parseInt(crc) === 8) return_value = 1000000
      return return_value
    })
    let channel_register_count_lt = data.channel_register_count.map(crc => {
      let return_value = undefined
      if(parseInt(crc) === 1) return_value = 50000
      if(parseInt(crc) >= 2 && parseInt(crc) < 7) return_value = (parseInt(crc)-1) * 100000
      if(parseInt(crc) === 7) return_value = 1000000
      return return_value
    })
    setChannelRegisterCountGt(channel_register_count_gt);
    setChannelRegisterCountLt(channel_register_count_lt);
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>検索フィルター{' '}</CardHeader>
        <CardBody>
          <SearchConditionsForm onSubmit={onSubmit} />
        </CardBody>
      </Card>
      {isSearch && (
        <InfuluencerTalentSearchResult 
          name={name}
          gender={gender}
          company={company}
          genderRatio={genderRatio}
          ageRange={ageRange}
          costGt={costGt}
          costLt={costLt}
          channelRegisterCountGt={channelRegisterCountGt}
          channelRegisterCountLt={channelRegisterCountLt}
          isEditPage={props.isEditPage}
          handleAddTalentPool={props.handleAddTalentPool}
        />
      )}
    </>
  );
};

export default InfluencerTalentSearchContainer;
