import gql from "graphql-tag";

const FETCH_TALENTS_QUERY = gql`
query talents($q: BaseScalar, $limit: Int, $offset: Int, $sort_type: String!, $sort_order: String!) {
  talents(q: $q, limit: $limit, offset: $offset, sortType: $sort_type, sortOrder: $sort_order){
    totalCount
    edges {
      node {
        id
        name
        gender
        birthday
        createdAt
        updatedAt
        talentPrimaryCategory{
          id
          name
        }
        talentSecondaryCategory{
          id
          name
        }
        talentTertiaryCategory{
          id
          name
        }
        talentCompany{
          id
          name
          createdAt
          updatedAt
        }
        talentGroup{
          id
          name
          nameKana
          createdAt
          updatedAt
        }
        talentNicknames{
          id
          name
          nameKanaFirst
          nameKanaLast
          createdAt
          updatedAt
        }
        talentNotes{
          id
          title
          contents
          createdAt
          updatedAt
        }
        talentImages{
          id
          imageUrl
          imageCategory
          createdAt
          updatedAt
        }
        talentTwitterCounts{
          id
          followCount
          followedCount
        }
        talentYoutubeCounts{
          id
          subscriberCount
          viewerCount
        }
        talentInstagramCounts{
          id
          followCount
          followedCount
          postCount
        }
      }
    }
  }
}`;

export default FETCH_TALENTS_QUERY;
