import React, {useEffect} from 'react';
import {useTable, useSortBy, usePagination} from "react-table";

const TalentPaginationTable = (props) => {
  const fetch_data = props.fetch_data;
  const {getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, state: { pageIndex, pageSize },} = useTable({columns: props.columns, data: props.data, initialState: { pageIndex: props.page }, manualPagination: true, pageCount: props.total_size}, useSortBy, usePagination);

  useEffect(() => {
    fetch_data( pageIndex, pageSize )
  }, [fetch_data, pageIndex, pageSize]);

  return (
    <>
      <table className="table" {...getTableProps}>
        <thead >
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th scope="col" {...column.getHeaderProps(column.getSortByToggleProps())} className={'text-center'}>
                {column.render('header')}
                <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className={'text-center'}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )})}
            </tr>
          );
        })}
        </tbody>
      </table>
      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>{' '}
        <span>
          Page{' '}
          <strong>{pageIndex + 1} of {pageOptions.length}</strong>{' '}
        </span>
      </div>
    </>
  );
};

export default TalentPaginationTable;
