import gql from "graphql-tag";

const FETCH_ACCOUNT_QUERY = gql`
query($uid: String!){
  account(uid: $uid){
    id
    role
    createdAt
    updatedAt
  }
}`;

export default FETCH_ACCOUNT_QUERY;
