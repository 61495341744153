import gql from "graphql-tag";

const FETCH_INFLUENCER_TALENT_COMPANIES_QUERY = gql`
query{
  influencerTalentCompanies{
    id
    name
    homepageUrl
    createdAt
    updatedAt
  }
}`;

export default FETCH_INFLUENCER_TALENT_COMPANIES_QUERY;
