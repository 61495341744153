import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const YoutubeAccountForm = (props) => {
  return (
    <FormGroup>
      <Label>YouTubeアカウント</Label>
      <Input type="text" id={'youtube_account'} name={'youtube_account'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default YoutubeAccountForm;
