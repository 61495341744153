import gql from "graphql-tag";

const FETCH_PROPOSAL_DOCUMENT_QUERY = gql`
query influencerTalentProposalDocument($account_id: Int!, $id: ID!) {
  influencerTalentProposalDocument(accountId: $account_id, id: $id){
    id
    name
    influencerTalentProposalDocumentItems{
      id
      influencerTalentProposalDocumentId
      influencerTalentId
      influencerTalentImageId
      influencerTalentOrder
      influencerTalent{
        id
        name
        gender
        createdAt
        updatedAt
        influencerTalentCompany{
          id
          name
          homepageUrl
          createdAt
          updatedAt
        }
        influencerTalentImages{
          id
          imageUrl
          imageCategory
          createdAt
          updatedAt
        }
      }
      influencerTalentImage{
        id
        imageUrl
        imageCategory
        createdAt
        updatedAt
      }
    }
  }
}`;

export default FETCH_PROPOSAL_DOCUMENT_QUERY;
