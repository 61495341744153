import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import "rc-slider/assets/index.css";
import CheckBoxButton from "../shared/checkbox_buttom";
import RadioBoxButton from "../shared/radiobox_button";

const EditCustom = (props) => {
  const { register, handleSubmit } = useForm({ defaultValues: {} });

  return (
    <>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <FormGroup>
                  <Label for={"name"} style={{fontWeight: 800}}>■ タレント名</Label>
                  <Input innerRef={register} id={"name"} name={"name"} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"name"} style={{fontWeight: 800}}>■ 性別</Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"gender-1"}
                      name={"gender"}
                      label={"男性"}
                      value={"男性"}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"gender-2"}
                      name={"gender"}
                      label={"女性"}
                      value={"女性"}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"gender-3"}
                      name={"gender"}
                      label={"グループ"}
                      value={"グループ"}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"gender_ratio"} style={{fontWeight: 800}}>■ 視聴者男女比</Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <RadioBoxButton
                      id={`gender-ratio-0`}
                      name={"gender_ratio"}
                      label={"条件なし"}
                      value={0}
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <RadioBoxButton
                      id={`gender-ratio-1`}
                      name={"gender_ratio"}
                      label={"男性８割以上（男性の視聴者層が80%以上）"}
                      value={1}
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <RadioBoxButton
                      id={`gender-ratio-2`}
                      name={"gender_ratio"}
                      label={"女性８割以上（女性の視聴者層が80%以上）"}
                      value={2}
                      innerRef={register}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <RadioBoxButton
                      id={`gender-ratio-3`}
                      name={"gender_ratio"}
                      label={"どっちにも人気"}
                      value={3}
                      innerRef={register}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"age"} style={{fontWeight: 800}}>■ 視聴者年齢層</Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"age-1"}
                      name={"age"}
                      label={"13-17歳"}
                      value={1}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"age-2"}
                      name={"age"}
                      label={"18-24歳"}
                      value={2}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"age-3"}
                      name={"age"}
                      label={"25-34歳"}
                      value={3}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"age-4"}
                      name={"age"}
                      label={"35-44歳"}
                      value={4}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"age-5"}
                      name={"age"}
                      label={"45-54歳"}
                      value={5}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={"age-6"}
                      name={"age"}
                      label={"55歳以上"}
                      value={6}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"company"} style={{fontWeight: 800}}>■ 事務所名</Label>
                </FormGroup>
                <FormGroup>
                  {props.companies.map((company, i) => {
                    return (
                      <FormGroup check inline>
                        <CheckBoxButton
                          id={`company-${i}`}
                          name={"company"}
                          label={company.name}
                          value={company.id}
                          innerRef={register}
                          colorNone={true}
                        />
                      </FormGroup>
                    );
                  })}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"cost"} style={{fontWeight: 800}}>■ 単価（仕入れ）</Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-1`}
                      name={"cost"}
                      label={"~50万"}
                      value={1}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-2`}
                      name={"cost"}
                      label={"51~100万"}
                      value={2}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-3`}
                      name={"cost"}
                      label={"101~200万"}
                      value={3}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-4`}
                      name={"cost"}
                      label={"201~300万"}
                      value={4}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-5`}
                      name={"cost"}
                      label={"301~400万"}
                      value={5}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-6`}
                      name={"cost"}
                      label={"401~500万"}
                      value={6}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`cost-7`}
                      name={"cost"}
                      label={"501万~"}
                      value={7}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"channel_register_count"} style={{fontWeight: 800}}>
                    ■ チャンネル登録者数
                  </Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-1`}
                      name={"channel_register_count"}
                      label={"1〜5万"}
                      value={1}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>

                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-2`}
                      name={"channel_register_count"}
                      label={"5〜10万"}
                      value={2}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-3`}
                      name={"channel_register_count"}
                      label={"10〜20万"}
                      value={3}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-4`}
                      name={"channel_register_count"}
                      label={"20〜30万"}
                      value={4}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-5`}
                      name={"channel_register_count"}
                      label={"30〜40万"}
                      value={5}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-6`}
                      name={"channel_register_count"}
                      label={"40〜50万"}
                      value={6}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-7`}
                      name={"channel_register_count"}
                      label={"50〜100万"}
                      value={7}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_register_count-8`}
                      name={"channel_register_count"}
                      label={"100万〜"}
                      value={8}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"channel_average_play_count"} style={{fontWeight: 800}}>■ 平均再生回数</Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-1`}
                      name={"channel_average_play_count"}
                      label={"〜1万"}
                      value={1}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-2`}
                      name={"channel_average_play_count"}
                      label={"1〜5万"}
                      value={2}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-3`}
                      name={"channel_average_play_count"}
                      label={"5〜10万"}
                      value={3}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-4`}
                      name={"channel_average_play_count"}
                      label={"10〜20万"}
                      value={4}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-5`}
                      name={"channel_average_play_count"}
                      label={"20〜30万"}
                      value={5}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-6`}
                      name={"channel_average_play_count"}
                      label={"30〜40万"}
                      value={6}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-7`}
                      name={"channel_average_play_count"}
                      label={"40〜50万"}
                      value={7}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_average_play_count-8`}
                      name={"channel_average_play_count"}
                      label={"50万〜"}
                      value={8}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for={"expected_play_count"} style={{fontWeight: 800}}>■ 想定再生数</Label>
                </FormGroup>
                <FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-1`}
                      name={"channel_expected_play_count"}
                      label={"〜1万"}
                      value={1}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-2`}
                      name={"channel_expected_play_count"}
                      label={"1〜5万"}
                      value={2}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-3`}
                      name={"channel_expected_play_count"}
                      label={"5〜10万"}
                      value={3}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-4`}
                      name={"channel_expected_play_count"}
                      label={"10〜20万"}
                      value={4}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-5`}
                      name={"channel_expected_play_count"}
                      label={"20〜30万"}
                      value={5}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-6`}
                      name={"channel_expected_play_count"}
                      label={"30〜40万"}
                      value={6}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-7`}
                      name={"channel_expected_play_count"}
                      label={"40〜50万"}
                      value={7}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                  <FormGroup check inline>
                    <CheckBoxButton
                      id={`channel_expected_play_count-8`}
                      name={"channel_expected_play_count"}
                      label={"50万〜"}
                      value={8}
                      innerRef={register}
                      colorNone={true}
                    />
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Button type="submit">検索</Button>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </form>
    </>
  );
};

export default EditCustom;
