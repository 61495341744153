import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const InstagramAccountForm = (props) => {
  return (
    <FormGroup>
      <Label>Instagramアカウント</Label>
      <Input type="text" id={'instagram_account'} name={'instagram_account'} innerRef={props.register} defaultValue={props.value}  />
    </FormGroup>
  );
};

export default InstagramAccountForm;
