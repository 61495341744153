export default {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
    },
    {
      name: 'タレントリスト',
      url: '/talents',
      icon: 'icon-docs',
      children: [
        {
          name: '新規リスト作成',
          url: '/proposal_documents/new',
          icon: 'icon-puzzle',
        },
        {
          name: '作成中リスト',
          url: '/proposal_documents/pending/list',
          icon: 'icon-puzzle',
        },
        {
          name: 'DL済みリスト',
          url: '/proposal_documents/done/list',
          icon: 'icon-puzzle',
        },
      ]
    },
    {
      name: 'YouTuberリスト',
      url: '/influencer_talents',
      icon: 'icon-docs',
      children: [
        {
          name: '新規リスト作成',
          url: '/influencer_talent_proposal_documents/new',
          icon: 'icon-puzzle',
        },
        {
          name: '作成中リスト',
          url: '/influencer_talent_proposal_documents/pending/list',
          icon: 'icon-puzzle',
        },
        {
          name: 'DL済みリスト',
          url: '/influencer_talent_proposal_documents/done/list',
          icon: 'icon-puzzle',
        },
      ]
    },
    // {
    //   name: 'Youtuber検索',
    //   url: '/proposal_documents/1/edit_custom',
    //   icon: 'icon-docs',
    // },
    {
      name: 'タレント',
      url: '/talents',
      icon: 'icon-docs',
      children: [
        {
          name: '検索',
          url: '/talents/list',
          icon: 'icon-puzzle',
        },
        {
          name: '新規作成',
          url: '/talents/new',
          icon: 'icon-puzzle',
        },
        {
          name: 'CSVインポート',
          url: '/talents/csv_import',
          icon: 'icon-puzzle',
        },
        {
          name: 'CSVエクスポート',
          url: '/talents/csv_export',
          icon: 'icon-docs',
          // badge: {
          //   variant: 'info',
          //   text: 'NEW',
          // },
        },
      ]
    },
    {
      name: '過去リスト',
      url: '/proposal_documents/past/search',
      icon: 'icon-docs',
      // badge: {
      //   variant: 'info',
      //   text: 'NEW',
      // },
    },
    {
      name: 'ユーザー管理',
      url: '/users',
      icon: 'icon-docs',
      children: [
        {
          name: '一覧',
          url: '/users/list',
          icon: 'icon-puzzle',
        },
        {
          name: '新規作成',
          url: '/users/new',
          icon: 'icon-puzzle',
        },
      ]
    },
  ]
};
