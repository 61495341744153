import {Badge, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import FETCH_TALENT_PRIMARY_CATEGORY_QUERY from "../../graphql/queries/talent_primary_category_query";

const CategoryPrimaryItemForm = (props) => {
  const {loading, data} = useQuery(FETCH_TALENT_PRIMARY_CATEGORY_QUERY);
  if(loading) return "Loading...";

  let badge = <></>;
  let register = props.register;
  if(props.required) {
    badge = <Badge color="danger">必須</Badge>;
    register = props.register({required: "大カテゴリーが選択されていません。"})
  }

  return (
    <FormGroup>
      <Label>大カテゴリー  {badge}</Label>
      <Input type="select" name={'categories.primary'} innerRef={register} onChange={props.onChange} defaultValue={props.value} >
        <option value="" selected="selected">--選択してください--</option>
        {data?.talentPrimaryCategories?.map(category => (
          <option value={category.name}>{category.name}</option>
        ))}
      </Input>
      { props.errors.categories && <span style={style.errorMsg}>{ props.errors.categories.primary.message }</span> }
    </FormGroup>
  );
};

const style = {
  errorMsg: {
    color: 'red'
  }
};

export default CategoryPrimaryItemForm;
