import gql from "graphql-tag";

const FETCH_ACCOUNTS_QUERY = gql`
query accounts($q: BaseScalar, $limit: Int, $offset: Int){
  accounts(q: $q, limit: $limit, offset: $offset){
    totalCount
    edges {
      node {
        id
        role
        email
        firebaseUid
        createdAt
      }
    }
  }
}`;

export default FETCH_ACCOUNTS_QUERY;
