import {FormGroup, Label} from "reactstrap";
import React from "react";
import CheckBoxButton from "../shared/checkbox_buttom";

const AgeRangeForm = (props) => {
  return (
    <>
      <FormGroup>
        <Label for={"age_range"} style={{fontWeight: 800}}>■ 視聴者年齢層</Label>
      </FormGroup>
      <FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={"age-1"}
            name={"age_range"}
            label={"13-17歳"}
            value={1}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={"age-2"}
            name={"age_range"}
            label={"18-24歳"}
            value={2}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={"age-3"}
            name={"age_range"}
            label={"25-34歳"}
            value={3}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={"age-4"}
            name={"age_range"}
            label={"35-44歳"}
            value={4}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={"age-5"}
            name={"age_range"}
            label={"45-54歳"}
            value={5}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={"age-6"}
            name={"age_range"}
            label={"55歳以上"}
            value={6}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
      </FormGroup>
    </>
  );
};

export default AgeRangeForm;
