import gql from "graphql-tag";

const ADD_DOCUMENT_QUERY = gql`
mutation createInfluencerTalentProposalDocument($account_id: Int!, $name: String!){
    createInfluencerTalentProposalDocument(input: {
      accountId: $account_id
      name: $name
    }){
      success
      influencerTalentProposalDocument{
        id
        name
      }
    }
}`;

export default ADD_DOCUMENT_QUERY;
