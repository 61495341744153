import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import EditCustomCheckedPaginationTable from "./edit_custom_checked_pagination_table";
import ExcelJS from "exceljs";


const EditCustomChecked = (props) => {
  const checkedTalentId = props.checkedTalentId;

  let outputTalents = []

  const onSubmit = async (e) => {
    e.preventDefault()

    outputTalents = JSON.parse(sessionStorage.getItem('checked_talents'))
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('My Sheet');
    const worksheet = workbook.getWorksheet('My Sheet');

    worksheet.columns = [
      { header: 'ID', key: 'id' },
      { header: 'タレント名', key: 'name' },
      { header: '性別', key: 'gender' },
      { header: '事務所', key: 'company' },
      { header: '金額', key: 'cost' },
      { header: 'Youtube URL', key: 'youtubeUrl' },
      { header: 'チャンネル登録者数', key: 'channelRegisterCount' },
      { header: '平均再生回数', key: 'channelAveragePlayCount' },
      { header: '想定再生数', key: 'channelExpectedPlayCount' },
    ];

    outputTalents.map(talent => {
      talent.company = talent.influencerTalentCompany.name
      talent.cost = Number(talent.cost).toLocaleString()
      talent.channelRegisterCount = Number(talent.channelRegisterCount).toLocaleString()
      talent.channelAveragePlayCount = Number(talent.channelAveragePlayCount).toLocaleString()
      talent.channelExpectedPlayCount = Number(talent.channelExpectedPlayCount).toLocaleString()
      worksheet.addRow((talent))
      return null
    })

    // UInt8Arrayを生成
    const uint8Array = await workbook.xlsx.writeBuffer();
    // Blobを生成
    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    // DL用URLを生成し、aタグからダウンロードを実行
    const url = window.URL.createObjectURL(blob);
    // aタグを生成
    const a = document.createElement('a');
    // aタグのURLを設定
    a.href = url;
    // aタグにdownload属性を付け、URLがダウンロード対象になるようにします
    a.download = `talent_list.xlsx`;
    // aタグをクリックさせます
    a.click();
    // ダウンロード後は不要なのでaタグを除去
    a.remove();
  }

  const columns = () => {
    const clms = [];
    clms.push({
      header: "タレント名",
      accessor: "name",
      Cell: (cell) => {return cell.row.original.name;},});
    clms.push({
      header: "性別",
      accessor: "gender",
      Cell: (cell) => {return <>{cell.row.original.gender}</>;},});
    clms.push({
      header: "事務所名",
      accessor: "influencerTalentCompany.name",
      Cell: (cell) => {return <>{cell.row.original.influencerTalentCompany.name}</>;},});
    clms.push({
      header: "Youtube URL",
      accessor: "youtubeUrl",
      Cell: (cell) => {return <><a href={cell.row.original.youtubeUrl} target="_blank" rel="noopener noreferrer">{cell.row.original.youtubeUrl}</a></>;},});
    clms.push({
      header: "金額",
      accessor: "cost",
      Cell: (cell) => {return <>{Number(cell.row.original.cost).toLocaleString()}</>;},});
    clms.push({
      header: "チャンネル登録者数",
      accessor: "channelRegisterCount",
      Cell: (cell) => {return <>{Number(cell.row.original.channelRegisterCount).toLocaleString()}</>;},});
    clms.push({
      header: "平均再生回数",
      accessor: "channelAveragePlayCount",
      Cell: (cell) => {return <>{Number(cell.row.original.channelAveragePlayCount).toLocaleString()}</>;},});
    clms.push({
      header: "想定再生数",
      accessor: "channelExpectedPlayCount",
      Cell: (cell) => {return <>{Number(cell.row.original.channelExpectedPlayCount).toLocaleString()}</>;},});
    return clms;
  };

  return (
    <>
      {checkedTalentId || sessionStorage.getItem('checked_talents') ? (
        <Card>
          <CardHeader>選択済み</CardHeader>
          <CardBody>
            <Row>
              <Col align={"center"}>
                <Button onClick={onSubmit}>リストに追加</Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <EditCustomCheckedPaginationTable
                  data={JSON.parse(sessionStorage.getItem('checked_talents'))}
                  columns={columns()}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      ) : (
        null
      )}
    </>
  );

};

export default EditCustomChecked;
