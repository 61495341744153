import {FormGroup, Input, Label} from "reactstrap";
import React from "react";
import {useQuery} from "@apollo/react-hooks";
import FETCH_TALENT_SECONDARY_CATEGORY_QUERY from "../../graphql/queries/talent_secondary_category_query";

const CategorySecondaryItemForm = (props) => {
  const {loading, data} = useQuery(FETCH_TALENT_SECONDARY_CATEGORY_QUERY, { variables: { talent_primary_category_name: props.category_primary } });
  if(loading) return "...";

  return (
    <FormGroup>
      <Label>中カテゴリー</Label>
      <Input type="select" name={'categories.secondary'} innerRef={props.register} onChange={props.onChange} defaultValue={props.value} >
        <option value="" selected="selected">--選択してください--</option>
        {data?.talentSecondaryCategories?.map(category => (
          <option value={category.name}>{category.name}</option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default CategorySecondaryItemForm;
