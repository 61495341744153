import {FormGroup, Input, Label} from "reactstrap";
import React from "react";
import native_places_data from "../../utils/native_places_data";

const NativePlaceForm = (props) => {

  let native_place = native_places_data.map(native_place_data => (
    <option value={native_place_data['name']}>{native_place_data['name']}</option>
  ));

  return (
    <FormGroup>
      <Label>出身地</Label>
      <Input type="select" name={'native_place'} id={'native_place'} innerRef={props.register} defaultValue={props.value} >
        <option value="" selected="selected">--選択してください--</option>
        {native_place}
      </Input>
    </FormGroup>
  );
};

export default NativePlaceForm;
