import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const CompanyUrlForm = (props) => {
  return (
    <FormGroup>
      <Label>所属事務所URL</Label>
      <Input type="text" id={'company_url'} name={'company_url'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default CompanyUrlForm;
