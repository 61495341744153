import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Col,
  ButtonGroup
} from "reactstrap";
import React from "react";
import SelectRoundedImage from "../shared/select_rounded_image";

const TalentPool = (props) => {
  let proposal_document_items = props.proposal_document_items.sort((a, b) => {
    if(a.talentOrder<b.talentOrder) return -1;
    if(a.talentOrder>b.talentOrder) return 1;
    return 0;
  });
  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>タレントプール{' '}</CardHeader>
        <CardBody>
          <FormGroup row>
            {proposal_document_items.map((proposal_document_item, index) => {
              return (
                <>
                  <Col md={2} className={'text-center'}>
                    <a href={`/talents/${proposal_document_item.talent.id}`} target={'__blank'}>{proposal_document_item.talent.name}</a>
                    <div className={'mb-2'} />
                    <SelectRoundedImage proposal_document_item={proposal_document_item} index={index} />
                    <div className={'mb-2'} />
                    <Button color={'danger'} onClick={() => props.handleDeleteTalentPool(proposal_document_item.talent)}>削除</Button>
                    <div>
                      <ButtonGroup>
                        <Button color={'primary'} onClick={() => props.handlePrevTalentPool(proposal_document_item.talent)}>←</Button>
                        <Button color={'primary'} onClick={() => props.handleNextTalentPool(proposal_document_item.talent)}>→</Button>
                      </ButtonGroup>
                    </div>
                  </Col>
                </>
              )})}
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
};

export default TalentPool;
