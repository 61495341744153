import gql from "graphql-tag";

const FETCH_TALENT_CSV_EXPORT_LOG_QUERY = gql`
query talentCsvExportLogs($q: BaseScalar, $limit: Int, $offset: Int) {
    talentCsvExportLogs(q: $q, limit: $limit, offset: $offset){
        totalCount
        edges {
            node {
                id
                csvFile
                progress
                talentCount
                createdAt
                updatedAt
            }
        }
    }
}`;

export default FETCH_TALENT_CSV_EXPORT_LOG_QUERY;
