import {InMemoryCache} from "apollo-cache-inmemory";
import {createHttpLink} from "apollo-link-http";
import ApolloClient from "apollo-client";
import {from} from "apollo-link";

const cache = new InMemoryCache();
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: operation => {
    operation.setContext({
      headers: {
        authorization: 'Bearer '
      }
    })
  }
});

const client = new ApolloClient({
  link: from([httpLink]),
  cache
});

export default client;
