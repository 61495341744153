import React, {useState} from "react";
import {useMutation} from "@apollo/react-hooks";
import ADD_DOCUMENT_ITEM_QUERY from "../../graphql/mutations/proposal_document_item_mutation";
import SelectImageType from "./select_image_type";
import TalentPoolRoundedImage from "../proposal_documents/talent_pool_rounded_image";

const SelectRoundedImage = (props) => {
  const [talent_image_id, set_talent_image_id] = useState(null);
  const [update_talent_image_id] = useMutation(ADD_DOCUMENT_ITEM_QUERY);

  const handle_image_select = (e) => {
    let talent_image_id = parseInt(e.target.value);
    set_talent_image_id(talent_image_id);

    update_talent_image_id({variables: {
        id: props.proposal_document_item.proposalDocumentId,
        talent_id: props.proposal_document_item.talentId,
        talent_image_id: talent_image_id,
        talent_order: props.proposal_document_item.talentOrder
      }}).then(r => {
      alert("写真を変更しました。");
    }).catch(e => {
      alert("変更に失敗しました。");
    });
  };

  return (
    <>
      <TalentPoolRoundedImage proposal_document_item={props.proposal_document_item} talent_image_id={talent_image_id} />
      <div className={'mb-2'} />
      <SelectImageType proposal_document_item={props.proposal_document_item}
                       image_id={props.proposal_document_item.talentImage?.id}
                       handle_image_select={handle_image_select} />
    </>
  );
};

export default SelectRoundedImage;
