import React from 'react';
import { Card, CardHeader, CardBody } from "reactstrap";

const Dashboard = (props) => {
  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>運用ルール</CardHeader>
        <CardBody>
          <ul>
            <li>
              <b>タレント名を検索して写真が出てこない、または画像の変更をしたい場合は、「タレント詳細画面_編集_画像を追加」と進み、 差し替えたい画像をアップロードする。（解像度の高い画像推奨）</b>
              <p>
                カテゴリ情報やSNS情報など、各情報の変更も同じ箇所から変更可能。<br />
                ※SNSのURLが入れ込まれていないとフォロワー数が反映されないので、入力されていない場合は編集より追記をお願いします。
              </p>
            </li>
            <li>
              <b>タレント名検索は苗字や名前だけでも可能。</b>
              <p>
                ただし、名前によっては大量の検索結果が出る可能性もあるため、フルネームでの検索を推奨
              </p>
            </li>
            <li>
              <b>「基本情報から探す」で条件に合うタレントを検索可能 （栃木県出身の20-30代の男性、など）</b>
            </li>
            <li>
              <b>「過去リスト」からタレント名を検索すると、過去にシステム経由で作成されたそのタレント名が含まれたリストが出てくる</b>
            </li>
          </ul>
        </CardBody>
      </Card>
    </>
  );
};

export default Dashboard;
