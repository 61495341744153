import React from "react";
import {Col, FormGroup, Input, Label} from "reactstrap";
import ImageDropdownForm from "./image_dropdown_form";

const ImageForm = (props) => {
    return (
      <>
        {props.fields.map((item, index) => {
          return (
            <>
              <FormGroup>
                <Label>画像URL{index+1}</Label>
                <FormGroup row>
                  <Col md={2}>
                    <Input type={'select'} name={`images[${index}].category`} defaultValue={item.category} innerRef={props.register}>
                      <option value={1}>バストアップ</option>
                      <option value={2}>顔アップ</option>
                      <option value={3}>SNS</option>
                      <option value={99}>その他</option>
                    </Input>
                  </Col>
                  <Col md={10}>
                    <Input type={'text'} name={`images[${index}].url`} defaultValue={item.url} innerRef={props.register} />
                    <div className={'mb-3'} />
                    <ImageDropdownForm index={index} name={`images[${index}].file`} innerRef={props.register}/>
                  </Col>
                </FormGroup>
              </FormGroup>
              <div className={'mb-3'} />
            </>
          );
        })}
      </>
    );
};

export default ImageForm;
