import React, { Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import FirebaseAuth from './FirebaseAuth';
import './App.scss';
import DefaultLayout from './layouts/DefaultLayout';
import Login from './containers/login';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <FirebaseAuth>
              <Switch>
                <Route path="/" name="Home" component={DefaultLayout} />
              </Switch>
            </FirebaseAuth>
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
