import React, {useState} from "react";
import {Button, Card, CardBody, CardFooter, Form} from "reactstrap";
import moment from "moment";
import CategoryPrimaryItemForm from "./category_primary_item_form";
import AgeRangeForm from "./age_range_form";
import NativePlaceForm from "./native_place_form";
import CompanyNameForm from "./company_name_form";
// import YearPriceForm from "./year_price_form";
import GenderForm from "./gender_form";
import {useForm} from "react-hook-form";
import CategorySecondaryItemForm from "./category_secondary_item_form";
import CategoryTertiaryItemForm from "./category_tertiary_item_form";

const SearchConditionsForm = (props) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      age_range: {
        first: '',
        last: ''
      },
      native_place: '',
      company_name: '',
      categories: {
        primary: '',
        secondary: '',
        tertiary: '',
      },
      gender: '',
      // year_price: 0
    }
  });
  const [category_primary, set_category_primary] = useState('');
  const [category_secondary, set_category_secondary] = useState('');

  const onSubmit = (data) => {
    console.log(data);
    props.setIsSearch(true);
    props.setNativePlace(data.native_place);
    props.setCompany(data.company_name);
    props.setPrimaryCategory(data.categories.primary);
    props.setSecondaryCategory(data.categories.secondary);
    props.setTertiaryCategory(data.categories.tertiary);
    props.setGender(data.gender);

    let age_range_first = data.age_range.first;
    let age_range_last = data.age_range.last;
    let min_age = age_range_first;
    let max_age = age_range_last;
    if(age_range_first < age_range_last){
      min_age = age_range_last;
      max_age = age_range_first;
    }

    let birthday_gt = (parseInt(min_age) === 0 || min_age === "") ? null : moment().subtract(parseInt(min_age), 'years');
    if (birthday_gt !== null) props.setBirthdayGt(birthday_gt.format('YYYY-MM-DD'));
    let birthday_lt = (parseInt(max_age) === 0 || max_age === "") ? null : moment().subtract(parseInt(max_age), 'years');
    if (birthday_lt !== null) props.setBirthdayLt(birthday_lt.format('YYYY-MM-DD'));
    // if(parseInt(data.year_price) !== 0) props.setYearPrice(data.year_price);
  };

  const handleChangeCategoryPrimary = (e) => {
    set_category_primary(e.target.value);
  };
  const handleChangeCategorySecondary = (e) => {
    set_category_secondary(e.target.value);
  };

  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <CardBody>
          <AgeRangeForm register={register} />
          <NativePlaceForm register={register} />
          <CompanyNameForm register={register} />
          {/*<YearPriceForm  register={register} />*/}
          <CategoryPrimaryItemForm register={register} errors={errors} onChange={handleChangeCategoryPrimary}  />
          <CategorySecondaryItemForm register={register} errors={errors} onChange={handleChangeCategorySecondary} category_primary={category_primary} />
          <CategoryTertiaryItemForm register={register} errors={errors} category_secondary={category_secondary} />
          <GenderForm register={register} />
        </CardBody>
        <CardFooter>
          <Button type="submit" color={'primary'}>検索する</Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export default SearchConditionsForm;
