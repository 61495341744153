import gql from "graphql-tag";

const FETCH_PROPOSAL_DOCUMENT_QUERY = gql`
query proposalDocument($account_id: Int!, $id: ID!) {
  proposalDocument(accountId: $account_id, id: $id){
    id
    name
    proposalDocumentItems{
      id
      proposalDocumentId
      talentId
      talentImageId
      talentOrder
      talent{
        id
        name
        gender
        birthday
        createdAt
        updatedAt
        talentPrimaryCategory{
          id
          name
        }
        talentSecondaryCategory{
          id
          name
        }
        talentTertiaryCategory{
          id
          name
        }
        talentCompany{
          id
          name
          createdAt
          updatedAt
        }
        talentGroup{
          id
          name
          nameKana
          createdAt
          updatedAt
        }
        talentNicknames{
          id
          name
          nameKanaFirst
          nameKanaLast
          createdAt
          updatedAt
        }
        talentNotes{
          id
          title
          contents
          createdAt
          updatedAt
        }
        talentImages{
          id
          imageUrl
          imageCategory
          createdAt
          updatedAt
        }
      }
      talentImage{
        id
        imageUrl
        imageCategory
        createdAt
        updatedAt
      }
    }
  }
}`;

export default FETCH_PROPOSAL_DOCUMENT_QUERY;
