import gql from "graphql-tag";

const TALENT_CSV_EXPORT_LOG_MUTATION = gql`
mutation createTalentCsvExportLog{
  createTalentCsvExportLog(input: {}){
    success
    talentCsvExportLog{
      id
    }
  }
}`;

export default TALENT_CSV_EXPORT_LOG_MUTATION;
