import React from "react";
import RoundedImage from "../shared/rounded_image";

const TalentPoolRoundedImage = (props) => {
  let image_url = "";

  if(props.influencer_talent_proposal_document_item.influencerTalentImage === null) return (<RoundedImage image_url={image_url} />);

  if(process.env.NODE_ENV === 'development') { image_url = process.env.REACT_APP_IMAGE_ENDPOINT; }

  image_url += props.influencer_talent_proposal_document_item.influencerTalentImage.imageUrl

  return (
    <RoundedImage image_url={image_url} />
  );
};

export default TalentPoolRoundedImage;
