import {FormGroup, Label} from "reactstrap";
import React from "react";
import CheckBoxButton from "../shared/checkbox_buttom";

const ChannelRegisterCountForm = (props) => {
  return (
    <>
      <FormGroup>
        <Label for={"channel_register_count"} style={{fontWeight: 800}}>
          ■ チャンネル登録者数
        </Label>
      </FormGroup>
      <FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-1`}
            name={"channel_register_count"}
            label={"1〜5万"}
            value={1}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>

        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-2`}
            name={"channel_register_count"}
            label={"5〜10万"}
            value={2}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-3`}
            name={"channel_register_count"}
            label={"10〜20万"}
            value={3}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-4`}
            name={"channel_register_count"}
            label={"20〜30万"}
            value={4}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-5`}
            name={"channel_register_count"}
            label={"30〜40万"}
            value={5}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-6`}
            name={"channel_register_count"}
            label={"40〜50万"}
            value={6}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-7`}
            name={"channel_register_count"}
            label={"50〜100万"}
            value={7}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`channel_register_count-8`}
            name={"channel_register_count"}
            label={"100万〜"}
            value={8}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
      </FormGroup>
    </>
  );
};

export default ChannelRegisterCountForm;
