import gql from "graphql-tag";

const FETCH_TALENT_PRIMARY_CATEGORY_QUERY = gql`
query{
  talentPrimaryCategories{
    id
    name
    createdAt
    updatedAt
  }
}`;

export default FETCH_TALENT_PRIMARY_CATEGORY_QUERY;
