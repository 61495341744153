// const calcAge = () => {
//
// };

const dateFormat = (date) => {
  if (date === null || date === '') {
    return "";
  }
  let dateArr = date.split('-');
  return dateArr[0]+"/"+dateArr[1]+"/"+dateArr[2]
};

export default dateFormat;
