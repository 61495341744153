import gql from "graphql-tag";

const FETCH_TALENT_TERTIARY_CATEGORY_QUERY = gql`
query talentTertiaryCategories($talent_secondary_category_name: String!){
  talentTertiaryCategories(talentSecondaryCategoryName: $talent_secondary_category_name){
    id
    name
    createdAt
    updatedAt
  }
}`;

export default FETCH_TALENT_TERTIARY_CATEGORY_QUERY;
