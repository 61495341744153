import {Col, FormGroup, Input, Label} from "reactstrap";
import React from "react";
import moment from "moment";

const BirthdayForm = (props) => {
  moment.locale('ja');
  let today = moment();

  let year_selection = [];
  for(let i=today.year(); i>today.year()-150; i--){
    year_selection.push(<option value={i}>{i}年</option>);
  }

  let month_selection = [];
  for(let i=1; i<13; i++){
    month_selection.push(<option value={i}>{i}月</option>);
  }

  let day_selection = [];
  for(let i=1; i<32; i++){
    day_selection.push(<option value={i}>{i}日</option>);
  }

  let birthday_form = (<></>);
  if(props.value){
    birthday_form = (
      <>
        <Col>
          <Input type="select" id={'birthday.year'} name={'birthday.year'} innerRef={props.register} defaultValue={props.value.year}>
            <option value="" selected="selected">--選択してください--</option>
            {year_selection}
          </Input>
        </Col>
        <Col>
          <Input type="select" id={'birthday.month'} name={'birthday.month'} innerRef={props.register} defaultValue={props.value.month}>
            <option value="" selected="selected">--選択してください--</option>
            {month_selection}
          </Input>
        </Col>
        <Col>
          <Input type="select" id={'birthday.day'} name={'birthday.day'} innerRef={props.register} defaultValue={props.value.day}>
            <option value="" selected="selected">--選択してください--</option>
            {day_selection}
          </Input>
        </Col>

      </>
    );
  }else{
    birthday_form = (
      <>
        <Col>
          <Input type="select" id={'birthday.year'} name={'birthday.year'} innerRef={props.register}>
            <option value="" selected="selected">--選択してください--</option>
            {year_selection}
          </Input>
        </Col>
        <Col>
          <Input type="select" id={'birthday.month'} name={'birthday.month'} innerRef={props.register}>
            <option value="" selected="selected">--選択してください--</option>
            {month_selection}
          </Input>
        </Col>
        <Col>
          <Input type="select" id={'birthday.day'} name={'birthday.day'} innerRef={props.register}>
            <option value="" selected="selected">--選択してください--</option>
            {day_selection}
          </Input>
        </Col>
      </>
    );
  }

  return (
    <FormGroup>
      <Label>生年月日</Label>
      <FormGroup row>
        {birthday_form}
      </FormGroup>
    </FormGroup>
  );
};

export default BirthdayForm;
