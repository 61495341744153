import React, {useState} from 'react'
import { Col, Row } from 'reactstrap'
import {useQuery} from "@apollo/react-hooks";
import EditCustom from "../../components/proposal_documents/edit_custom"
import EditCustomChecked from "../../components/proposal_documents/edit_custom_checked"
import EditCustomSearch from "../../components/proposal_documents/edit_custom_searh"
import FETCH_INFLUENCER_TALENT_COMPANIES_QUERY from "../../graphql/queries/influencer_talent_company_query";

const EditCustomContainer = () => {
  const [checkedTalentId, setCheckedTalentId] = useState([]);
  const [search_params, set_search_params] = useState([]);
  const {loading, error, data} = useQuery(FETCH_INFLUENCER_TALENT_COMPANIES_QUERY);
  if(loading) return "...";
  if(error) return "エラーが発生しました。"+error;

  const changeCheckedTalentId = (isState) => {
    setCheckedTalentId(isState);
  }

  const onSubmit = (v) => {
    let cost_gt = v.cost.map(c => {
      let return_value = undefined
      if(parseInt(c) === 1) return_value = 0
      if(parseInt(c) === 2) return_value = 510000
      if(parseInt(c) >= 3) return_value = ((c-2)*100+1) * 10000
      return return_value
    })
    let cost_lt = v.cost.map(c => {
      let return_value = undefined
      if(parseInt(c) === 1) return_value = 500000
      if(parseInt(c) >= 2 && parseInt(c) <= 6) return_value = ((parseInt(c)-1) * 100) * 10000
      return return_value
    })
    let channel_register_count_gt = v.channel_register_count.map(crc => {
      let return_value = undefined
      if(parseInt(crc) === 1) return_value = 10000
      if(parseInt(crc) === 2) return_value = 50000
      if(parseInt(crc) >= 3 && parseInt(crc) < 8) return_value = (parseInt(crc)-2) * 100000
      if(parseInt(crc) === 8) return_value = 1000000
      return return_value
    })
    let channel_register_count_lt = v.channel_register_count.map(crc => {
      let return_value = undefined
      if(parseInt(crc) === 1) return_value = 50000
      if(parseInt(crc) >= 2 && parseInt(crc) < 7) return_value = (parseInt(crc)-1) * 100000
      if(parseInt(crc) === 7) return_value = 1000000
      return return_value
    })
    let channel_average_play_count_gt = v.channel_average_play_count.map(capc => {
      let return_value = undefined
      if(parseInt(capc) === 1) return_value = 0
      if(parseInt(capc) === 2) return_value = 10000
      if(parseInt(capc) === 3) return_value = 50000
      if(parseInt(capc) >= 4) return_value = (parseInt(capc) - 3) * 100000
      return return_value
    })
    let channel_average_play_count_lt = v.channel_average_play_count.map(capc => {
      let return_value = undefined
      if(parseInt(capc) === 1) return_value = 10000
      if(parseInt(capc) === 2) return_value = 50000
      if(parseInt(capc) >= 3 && parseInt(capc) > 8) return_value = (parseInt(capc) - 2) * 100000
      return return_value
    })
    let channel_expected_play_count_gt = v.channel_expected_play_count.map(cepc => {
      let return_value = undefined
      if(parseInt(cepc) === 1) return_value = 0
      if(parseInt(cepc) === 2) return_value = 10000
      if(parseInt(cepc) === 3) return_value = 50000
      if(parseInt(cepc) >= 4) return_value = (parseInt(cepc) - 3) * 100000
      return return_value
    })
    let channel_expected_play_count_lt = v.channel_expected_play_count.map(cepc => {
      let return_value = undefined
      if(parseInt(cepc) === 1) return_value = 10000
      if(parseInt(cepc) === 2) return_value = 50000
      if(parseInt(cepc) >= 3 && parseInt(cepc) > 8) return_value = (parseInt(cepc) - 2) * 100000
      return return_value
    })
    console.log(v)

    let params = {
      cost_gt: cost_gt,
      cost_lt: cost_lt,
      channel_register_count_gt: channel_register_count_gt,
      channel_register_count_lt: channel_register_count_lt,
      channel_average_play_count_gt: channel_average_play_count_gt,
      channel_average_play_count_lt: channel_average_play_count_lt,
      channel_expected_play_count_gt: channel_expected_play_count_gt,
      channel_expected_play_count_lt: channel_expected_play_count_lt,
    }
    set_search_params(Object.assign(v, params))
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <EditCustom
            companies={data.influencerTalentCompanies}
            onSubmit={onSubmit}
          />
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <EditCustomChecked
            checkedTalentId={sessionStorage.getItem('checked_talents') ? checkedTalentId : null}
          />
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <EditCustomSearch
            search_params={search_params}
            changeCheckedTalentId={changeCheckedTalentId}
          />
        </Col>
      </Row>
    </>
  )

}

export default EditCustomContainer
