import gql from "graphql-tag";

const DELETE_ACCOUNT_QUERY = gql`
mutation deleteAccount($id: ID!){
  deleteAccount(input: {id: $id}){
    success
  }
}`;

export default DELETE_ACCOUNT_QUERY;
