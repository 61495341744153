import gql from "graphql-tag";

const UPDATE_DOCUMENT_ITEM_QUERY = gql`
mutation updateInfluencerTalentProposalDocumentItem($id: Int!, $influencer_talent_id: Int!, $influencer_talent_image_id: Int!, $influencer_talent_order: Int!){
  updateInfluencerTalentProposalDocumentItem(input: {
    id: $id
    influencerTalentId: $influencer_talent_id
    influencerTalentImageId: $influencer_talent_image_id
    influencerTalentOrder: $influencer_talent_order
  }){
    success
    influencerTalentProposalDocumentItems{
      id
      influencerTalentProposalDocumentId
      influencerTalentId
      influencerTalentImageId
      influencerTalentOrder
      influencerTalent{
        id
        name
        gender
        createdAt
        updatedAt
        influencerTalentCompany{
          id
          name
          homepageUrl
          createdAt
          updatedAt
        }
        influencerTalentImages{
          id
          imageUrl
          imageCategory
          createdAt
          updatedAt
        }
      }
      influencerTalentImage{
        id
        imageUrl
        imageCategory
        createdAt
        updatedAt
      }
    }
  }
}`;

export default UPDATE_DOCUMENT_ITEM_QUERY;
