import React from 'react';
import {Button, Card, CardBody, CardHeader, Form, FormGroup} from "reactstrap";
import {useForm} from "react-hook-form";
import NameForm from "../../components/influencer_talent_proposal_documents/name_form";
import {useHistory} from 'react-router-dom';
import ADD_DOCUMENT_QUERY from "../../graphql/mutations/influencer_talent_proposal_document_mutation";
import {useMutation} from "@apollo/react-hooks";

const New = (props) => {
  const history = useHistory();
  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: ''
    }
  });
  const [addInfluencerTalentProposalDocument] = useMutation(ADD_DOCUMENT_QUERY);

  const onSubmit = (data) => {
    let account_id = parseInt(localStorage.getItem("account_id"));
    addInfluencerTalentProposalDocument({variables: {account_id: account_id, name: data.name}})
      .then(result => {
        // let success = result.data.createProposalDocument.success;
        let influencer_talent_proposal_document = result.data.createInfluencerTalentProposalDocument.influencerTalentProposalDocument;
        alert("『"+influencer_talent_proposal_document.name+"』が作成されました。");
        history.push(`/influencer_talent_proposal_documents/${influencer_talent_proposal_document.id}/edit`);
      })
      .catch(error => {
        alert("エラーが発生しました。");
      });
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>新規リスト作成{' '}</CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <NameForm register={register} />
            <FormGroup className={'text-right'}>
              <Button type={'submit'} color={'primary'}>作成</Button>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default New;
