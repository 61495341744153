import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const WeiboAccountForm = (props) => {
  return (
    <FormGroup>
      <Label>weiboアカウント</Label>
      <Input type="text" id={'weibo_account'} name={'weibo_account'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default WeiboAccountForm;
