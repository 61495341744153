import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row} from "reactstrap";
import {useForm} from "react-hook-form";
import {useMutation} from "@apollo/react-hooks";
import ADD_ACCOUNT_QUERY from "../../graphql/mutations/account_mutation";
import {useHistory} from "react-router-dom";

const New = (props) => {
  const history = useHistory();

  const [addAccount] = useMutation(ADD_ACCOUNT_QUERY);
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onSubmit = (data) => {
    addAccount({variables: {email: data.email, password: data.password, role: parseInt(data.role)}})
      .then(result => {
        alert(`『${data.email}』のユーザーを作成しました。`);
        history.go(0);
      }).catch(error => {
        alert(`『${data.email}』のユーザーの作成に失敗しました。${error}`);
      });
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>ユーザー作成{' '}</CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label>メールアドレス</Label>
              <InputGroup className="mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-user"/>
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="text" id="email" name="email" placeholder="メールアドレス" autoComplete="email" innerRef={register} />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>パスワード</Label>
              <InputGroup className="mb-4">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="icon-lock"/>
                  </InputGroupText>
                </InputGroupAddon>
                <Input type="password" id="password" name="password" placeholder="パスワード" autoComplete="current-password" innerRef={register} />
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>権限</Label>
              <InputGroup className="mb-4">
                <Input type="select" id="role" name="role" innerRef={register}>
                  <option value={2}>manager</option>
                  <option value={3}>staff</option>
                  <option value={4}>worker</option>
                </Input>
              </InputGroup>
            </FormGroup>
            <Row>
              <Col xs="6">
                <Button type="submit" color="primary" className="px-4 btn btn-primary">作成</Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default New;
