import gql from "graphql-tag";

const DELETE_DOCUMENT_ITEM_QUERY = gql`
mutation deleteInfluencerTalentProposalDocumentItem($id: Int!, $influencer_talent_id: Int!){
  deleteInfluencerTalentProposalDocumentItem(input: {
    id: $id
    influencerTalentId: $influencer_talent_id
  }){
    success
    influencerTalentProposalDocumentItems{
      id
      influencerTalentProposalDocumentId
      influencerTalentId
      influencerTalentImageId
      influencerTalentOrder
      influencerTalent{
        id
        name
        gender
        createdAt
        updatedAt
        influencerTalentCompany{
          id
          name
          createdAt
          updatedAt
        }
        influencerTalentImages{
          id
          imageUrl
          imageCategory
          createdAt
          updatedAt
        }
      }
      influencerTalentImage{
        id
        imageUrl
        imageCategory
        createdAt
        updatedAt
      }
    }
  }
}`;

export default DELETE_DOCUMENT_ITEM_QUERY;
