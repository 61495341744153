import React from 'react';
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import calcAge from "../../utils/calc_age";
import {useQuery} from "react-apollo";
import FETCH_TALENT_QUERY from "../../graphql/queries/talent_query";
import {useHistory} from "react-router-dom";
import dateFormat from "../../utils/date_format";

const Show = (props) => {
  let id = props.match.params.id;
  const history = useHistory();

  const {loading, error, data} = useQuery(FETCH_TALENT_QUERY, { variables: { id: id }, fetchPolicy: "cache-and-network" });
  if(loading) return "Loading...";
  if(error) return "エラーが発生しました。" + error;

  const talent = data.talent;
  const handleEditPage = () => {
    history.push(`/talents/${id}/edit`);
  };

  const title_label = (talent_image) => {
    let title = "";
    console.log(talent_image);
    if(talent_image.imageCategory === 'bust_up'){
      title = " (バストアップ)"
    }else if(talent_image.imageCategory === 'face_up'){
      title = " (顔アップ)"
    }else if(talent_image.imageCategory === 'sns'){
      title = " (SNS)"
    }else{
      title = " (その他)";
    }
    return title
  };

  const title_num_label = (talent_image, index) => {
    let title = "";
    console.log(talent_image);
    if(talent_image.imageCategory === 'bust_up'){
      title = "1"
    }else if(talent_image.imageCategory === 'face_up'){
      title = "2"
    }else if(talent_image.imageCategory === 'sns'){
      title = "3"
    }else{
      title = index;
    }
    return title
  };

  return (
    <>
      <Card>
        <CardBody>
          <div className={'text-right'}>
            <Button onClick={handleEditPage} color={'primary'}>編集</Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <i className="icon-menu"/>タレント詳細{' '}
        </CardHeader>
        <CardBody>
          <table className="table table-bordered">
            <thead>
            <tr>
              <th>タイトル</th>
              <th>項目名</th>
            </tr>
            </thead>
            <tbody>
            <tr key={talent?.name}>
              <th>名前</th>
              <td>{talent?.name}</td>
            </tr>
            {talent?.talentNicknames.map((talentNickname, index) =>
              <tr key={index}>
                <th>ニックネーム {index+1}</th>
                <td>{talentNickname.name}</td>
              </tr>
            )}
            {talent.talentImages.map((talent_image, index) => {
              return (
              <tr key={index}>
                <th>画像{title_num_label(talent_image, index)}{title_label(talent_image)}</th>
                <td>
                  {process.env.NODE_ENV === 'development' &&
                    <img src={process.env.REACT_APP_IMAGE_ENDPOINT + talent_image.imageUrl} alt={talent?.name} style={style.image} />
                  }
                  {process.env.NODE_ENV !== 'development' &&
                    <img src={talent_image.imageUrl} alt={talent?.name} style={style.image} />
                  }
                </td>
              </tr>
            )})}
            <tr>
              <th>性別</th>
              <td>{talent.gender}</td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>{dateFormat(talent.birthday)}</td>
            </tr>
            <tr>
              <th>年齢</th>
              <td>{calcAge(talent.birthday)}</td>
            </tr>
            <tr>
              <th>グループ</th>
              <td>{talent?.talentGroup?.name}</td>
            </tr>
            <tr>
              <th>所属事務所</th>
              <td>{talent?.talentCompany?.name}</td>
            </tr>
            <tr>
              <th>所属事務所URL</th>
              <td><a href={talent?.homepageUrl} target={'__blank'}>{talent?.homepageUrl}</a></td>
            </tr>
            <tr>
              <th>出身地</th>
              <td>{talent?.nativePlace}</td>
            </tr>
            <tr>
              <th>twitterアカウント</th>
              <td><a href={talent?.twitterAccount} target={'__blank'}>{talent?.twitterAccount}</a></td>
            </tr>
            <tr>
              <th>Instagramアカウント</th>
              <td><a href={talent?.instagramAccount} target={'__blank'}>{talent?.instagramAccount}</a></td>
            </tr>
            <tr>
              <th>Youtubeアカウント</th>
              <td><a href={talent?.youtubeAccount} target={'__blank'}>{talent?.youtubeAccount}</a></td>
            </tr>
            <tr>
              <th>blogアカウント</th>
              <td><a href={talent?.blogUrl} target={'__blank'}>{talent?.blogUrl}</a></td>
            </tr>
            <tr>
              <th>weiboアカウント</th>
              <td><a href={talent?.weiboAccount} target={'__blank'}>{talent?.weiboAccount}</a></td>
            </tr>
            <tr>
              <th>プロフィール</th>
              <td>{talent?.profile}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>
                <h1>{talent?.talentNotes?.title}</h1>
                <p>{talent?.talentNotes?.contents}</p>
              </td>
            </tr>
            </tbody>
          </table>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <table className="table table-bordered">
            <tr>
              <th></th>
              <th>フォロー数</th>
              <th>フォロワー数</th>
            </tr>
            <tr>
              <th>Twitter</th>
              <td>{(talent?.talentTwitterCounts[0]?.followCount ? talent?.talentTwitterCounts[0]?.followCount : 0).toLocaleString()}</td>
              <td>{(talent?.talentTwitterCounts[0]?.followedCount ? talent?.talentTwitterCounts[0]?.followedCount : 0).toLocaleString()}</td>
            </tr>
          </table>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <table className="table table-bordered">
            <tr>
              <th></th>
              <th>フォロー数</th>
              <th>フォロワー数</th>
              <th>投稿数</th>
            </tr>
            <tr>
              <th>Instagram</th>
              <td>{(talent?.talentInstagramCounts[0]?.followCount ? talent?.talentInstagramCounts[0]?.followCount : 0).toLocaleString()}</td>
              <td>{(talent?.talentInstagramCounts[0]?.followedCount ? talent?.talentInstagramCounts[0]?.followedCount : 0).toLocaleString()}</td>
              <td>{(talent?.talentInstagramCounts[0]?.postCount ? talent?.talentInstagramCounts[0]?.postCount : 0).toLocaleString()}</td>
            </tr>
          </table>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <table className="table table-bordered">
            <tr>
              <th></th>
              <th>チャンネル登録者数</th>
              <th>チャンネル視聴者数</th>
            </tr>
            <tr>
              <th>Youtube</th>
              <td>{(talent?.talentYoutubeCounts[0]?.subscriberCount ? talent?.talentYoutubeCounts[0]?.subscriberCount : 0).toLocaleString()}</td>
              <td>{(talent?.talentYoutubeCounts[0]?.viewerCount ? talent?.talentYoutubeCounts[0]?.viewerCount : 0).toLocaleString()}</td>
            </tr>
          </table>
        </CardBody>
      </Card>
    </>
  );
};

const style = {
  image: {
    maxWidth: 260,
    maxHeight: 180
  }
};

export default Show;
