import React from 'react';
import TalentSearchContainer from "../../components/talents/talent_search_container";

const Index = (props) => {
  return (
    <>
      <TalentSearchContainer/>
    </>
  );
};

export default Index;
