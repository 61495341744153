import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Col,
  ButtonGroup
} from "reactstrap";
import React from "react";
import SelectRoundedImage from "./select_rounded_image";

const InfluencerTalentPool = (props) => {
  let influencer_talent_proposal_document_items = props.influencer_talent_proposal_document_items.sort((a, b) => {
    if(a.influencerTalentOrder<b.influencerTalentOrder) return -1;
    if(a.influencerTalentOrder>b.influencerTalentOrder) return 1;
    return 0;
  });
  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>タレントプール{' '}</CardHeader>
        <CardBody>
          <FormGroup row>
            {influencer_talent_proposal_document_items.map((influencer_talent_proposal_document_item, index) => {
              let button = null
              if(influencer_talent_proposal_document_items.length > 1) {
                if(index===0){
                  button = <Button color={'primary'} onClick={() => props.handleNextTalentPool(influencer_talent_proposal_document_item.influencerTalent)}>→</Button>
                }else if(influencer_talent_proposal_document_item === influencer_talent_proposal_document_items.slice(-1)[0]){
                  button = <Button color={'primary'} onClick={() => props.handlePrevTalentPool(influencer_talent_proposal_document_item.influencerTalent)}>←</Button>
                }else{
                  button = (
                    <>
                      <Button color={'primary'} onClick={() => props.handlePrevTalentPool(influencer_talent_proposal_document_item.influencerTalent)}>←</Button>
                      <Button color={'primary'} onClick={() => props.handleNextTalentPool(influencer_talent_proposal_document_item.influencerTalent)}>→</Button>
                    </>
                  )
                }
              }
              return (
                <>
                  <Col md={2} className={'text-center'}>
                    {influencer_talent_proposal_document_item.influencerTalent.name}
                    <div className={'mb-2'} />
                    <SelectRoundedImage influencer_talent_proposal_document_item={influencer_talent_proposal_document_item} index={index} />
                    <div className={'mb-2'} />
                    <Button color={'danger'} onClick={() => props.handleDeleteTalentPool(influencer_talent_proposal_document_item.influencerTalent)}>削除</Button>
                    <div className={'mb-2'} />
                    <div>
                      <ButtonGroup>
                        {button}
                      </ButtonGroup>
                    </div>
                  </Col>
                </>
              )})}
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
};

export default InfluencerTalentPool;
