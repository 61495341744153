import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const TwitterAccountForm = (props) => {
  return (
    <FormGroup>
      <Label>twitterアカウント</Label>
      <Input type="text" id={'twitter_account'} name={'twitter_account'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default TwitterAccountForm;
