import {FormGroup, Input, Label} from "reactstrap";
import React from "react";

const CompanyNameForm = (props) => {
  return (
    <FormGroup>
      <Label>所属事務所名</Label>
      <Input type="text" id={'company_name'} name={'company_name'} innerRef={props.register} defaultValue={props.value} />
    </FormGroup>
  );
};

export default CompanyNameForm;
