import React, {useState} from "react";
import {useQuery} from "@apollo/react-hooks";
import FETCH_TALENTS_QUERY from "../../graphql/queries/talents_query";
import {Link} from "react-router-dom";
import {Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import SearchNameForm from "./talent_search_name_form";
import SearchConditionsForm from "./talent_search_conditions_form";
import TalentPaginationTable from "./talent_pagination_table";
import calcAge from "../../utils/calc_age";

const TalentSearchContainer = (props) => {
  const [activeTab, setActiveTab] = useState(new Array(4).fill('1'));
  const [name, setName] = useState('');
  const [nativePlace, setNativePlace] = useState('');
  const [company, setCompany] = useState('');
  const [primaryCategory, setPrimaryCategory] = useState('');
  const [secondaryCategory, setSecondaryCategory] = useState('');
  const [tertiaryCategory, setTertiaryCategory] = useState('');
  const [gender, setGender] = useState('');
  const [birthdayGt, setBirthdayGt] = useState(null);
  const [birthdayLt, setBirthdayLt] = useState(null);
  const [yearPrice, setYearPrice] = useState(null);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortType] = useState('talents.name');
  const [sortOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const [isSearch, setIsSearch] = useState(false);

  let variables = {
    q: {
      name_or_talent_group_name_or_talent_nicknames_name_or_talent_nicknames_name_kana_first_or_talent_nicknames_name_kana_last_cont: name,
      native_place_cont: nativePlace,
      talent_company_name_cont: company,
      talent_primary_category_name_eq: primaryCategory,
      talent_secondary_category_name_eq: secondaryCategory,
      talent_tertiary_category_name_eq: tertiaryCategory,
      gender_eq: gender,
    },
    limit: limit,
    offset: offset,
    sort_type: sortType,
    sort_order: sortOrder,
  };
  if(birthdayGt !== null) variables['q']['birthday_gt'] = birthdayGt;
  if(birthdayLt !== null) variables['q']['birthday_lt'] = birthdayLt;
  if(yearPrice !== null) variables['q']['year_price_gteq'] = yearPrice;

  const {loading, error, data} = useQuery(FETCH_TALENTS_QUERY, {variables: variables});
  if(loading) return "...";
  if(error) return "エラーが発生しました。"+error;

  const toggle = (tabPane, tab) => {
    const newArray = activeTab.slice();
    newArray[tabPane] = tab;
    setActiveTab(newArray);
  };

  const handleFetchData = (index, size) => {
    setPage(index);
    setOffset(index*size);
  };

  const columns = [
    {header: '名前', accessor: 'name', Cell: cell => { return (<Link to={'/talents/'+cell.row.original.id} target={'_blank'}>{cell.row.original.name}</Link>) }},
    {header: '年齢', accessor: 'age', Cell: cell => { return (<>{cell.row.original.birthday ? calcAge(cell.row.original.birthday) : "-"}</>) }},
    {header: '所属事務所', accessor: 'company', Cell: cell => { return (<>{cell.row.original.talentCompany?.name ? cell.row.original.talentCompany?.name : "-"}</>) }},
    {header: '小カテゴリ', accessor: 'tertiary_category', Cell: cell => { return (<>{cell.row.original.talentTertiaryCategory?.name ? cell.row.original.talentTertiaryCategory?.name : "-"}</>) }},
    {header: 'twitterフォロワー数', accessor: 'twitter_follower_count', Cell: cell => { return (<>{cell.row.original.talentTwitterCounts[0]?.followedCount ? cell.row.original.talentTwitterCounts[0]?.followedCount : 0}</>) }},
    {header: 'instagramフォロワー数', accessor: 'instagram_follower_count', Cell: cell => { return (<>{cell.row.original.talentInstagramCounts[0]?.followedCount ? cell.row.original.talentInstagramCounts[0]?.followedCount : 0}</>) }},
    {header: 'youtubeチャンネル登録者数', accessor: 'youtube_channel_subscriber_count', Cell: cell => { return (<>{cell.row.original.talentYoutubeCounts[0]?.subscriberCount ? cell.row.original.talentYoutubeCounts[0]?.subscriberCount : 0}</>) }},
    {header: 'youtubeチャンネル視聴者数', accessor: 'youtube_channel_viewer_count', Cell: cell => { return (<>{cell.row.original.talentYoutubeCounts[0]?.viewerCount ? cell.row.original.talentYoutubeCounts[0]?.viewerCount : 0}</>) }},
  ];

  if(props.isEditPage){
    columns.push(
      {header: '-', accessor: '-', Cell: cell => { return (
          <>
            <Button color={'primary'} onClick={() => props.handleAddTalentPool(cell.row.original)}>タレントプールに追加する</Button>
          </>
        )}}
    );
  }

  let talents = data.talents.edges;
  talents = talents.map(edge => edge.node);

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>検索フィルター{' '}</CardHeader>
        <CardBody>
          <Nav tabs>
            <NavItem><NavLink active={activeTab[0] === '1'} onClick={() => { toggle(0, '1'); }}>デフォルト</NavLink></NavItem>
            <NavItem><NavLink active={activeTab[0] === '2'} onClick={() => { toggle(0, '2'); }}>基本情報から探す</NavLink></NavItem>
          </Nav>
          <TabContent activeTab={activeTab[0]}>
            <TabPane tabId="1"><SearchNameForm setIsSearch={setIsSearch} setName={setName} /></TabPane>
            <TabPane tabId="2">
              <SearchConditionsForm setIsSearch={setIsSearch}
                                    setBirthdayGt={setBirthdayGt}
                                    setBirthdayLt={setBirthdayLt}
                                    setYearPrice={setYearPrice}
                                    setNativePlace={setNativePlace}
                                    setCompany={setCompany}
                                    setPrimaryCategory={setPrimaryCategory}
                                    setSecondaryCategory={setSecondaryCategory}
                                    setTertiaryCategory={setTertiaryCategory}
                                    setGender={setGender} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
      {isSearch &&
        <Card>
          <CardHeader><i className="icon-menu"/>検索結果{' '}</CardHeader>
          <CardBody>
            <TalentPaginationTable
              data={talents}
              columns={columns}
              page={page}
              total_size={Math.ceil(data.talents.totalCount/limit)}
              fetch_data={handleFetchData} />
          </CardBody>
        </Card>
      }
    </>
  );
};

export default TalentSearchContainer;
