import React from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Progress} from "reactstrap";
import TalentCsvPaginationTable from "../../components/talents/talent_csv_pagination_table";
import {useMutation, useQuery} from "@apollo/react-hooks";
import FETCH_TALENT_CSV_EXPORT_LOG_QUERY from "../../graphql/queries/talent_csv_export_log_query";
import TALENT_CSV_EXPORT_LOG_MUTATION from "../../graphql/mutations/talent_csv_export_log_mutation";
import {useHistory} from "react-router-dom";

const CSVExport = () => {
  const history = useHistory();
  const [add_talent_csv_export_log] = useMutation(TALENT_CSV_EXPORT_LOG_MUTATION)
  const {loading, error, data} = useQuery(FETCH_TALENT_CSV_EXPORT_LOG_QUERY, {variables: {
      q: '',
      limit: 20,
      offset: 0
  }});
  if(loading) return "...";
  if(error) return "エラーが発生しています。" + error;
  let talent_csv_import_logs = data.talentCsvExportLogs;
  let table_data =talent_csv_import_logs.edges.map(talent_csv_import_log => talent_csv_import_log.node);

  const handleOnCreateCsv = () => {
    add_talent_csv_export_log()
      .then(result => {
        alert("CSVが作成されました。")
        history.go(0)
      }).catch(e => {
        alert("エラー")
      });
  };
  const handleOnFetchData = () => {};

  const columns = [
    {Header: 'ID', accessor: 'id'},
    {Header: 'タレント数', accessor: 'talent_count', Cell: cell => { return (<span>{cell.row.original.talentCount}人</span>) }},
    {Header: '進捗', accessor: 'progress', Cell: cell => { return (
        <>
          <div className="text-center">{cell.row.original.progress}%</div>
          <Progress color="info" value={cell.row.original.progress} />
        </>
    )}},
    {Header: 'ダウンロードリンク', accessor: 'csvFile', Cell: cell => {
      if(100 !== cell.row.original.progress){
        return (<Button color={'primary'} disabled={true}>CSVダウンロード</Button>)
      }else{
        return (<a className={'btn btn-primary text-white'} href={cell.row.original.csvFile} download={true}>CSVダウンロード</a>)
      }
    }},
  ];

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>作成{' '}</CardHeader>
        <CardFooter className={'text-right'}>
          <Button color={'primary'} onClick={handleOnCreateCsv}>CSV作成</Button>
        </CardFooter>
      </Card>
      <Card>
        <CardHeader><i className="icon-menu"/>一覧{' '}</CardHeader>
        <CardBody>
          <TalentCsvPaginationTable
            data={table_data}
            columns={columns}
            total_size={Math.ceil(talent_csv_import_logs.totalCount/10)}
            fetch_data={handleOnFetchData} />
        </CardBody>
      </Card>
    </>
  );
};

export default CSVExport;
