import React, {useState} from 'react';
import {useQuery} from "@apollo/react-hooks";
import FETCH_PROPOSAL_DOCUMENTS_QUERY from "../../graphql/queries/proposal_documents_query";
import PastProposalDocumentPaginationTable from "./past_proposal_document_pagination_table";
import {Link} from "react-router-dom";
import {Card, CardBody, CardHeader} from "reactstrap";

const PastProposalDocumentContainer = (props) => {
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortType] = useState('talents.name');
  const [sortOrder] = useState('desc');
  const [page, setPage] = useState(0);

  const prefix = "talents"
  let variables = {
    q: {},
    limit: limit,
    offset: offset,
    sort_type: sortType,
    sort_order: sortOrder,
  };
  variables['q'][`${prefix}_name_or_${prefix}_talent_group_name_or_${prefix}_talent_nicknames_name_or_${prefix}_talent_nicknames_name_kana_first_or_${prefix}_talent_nicknames_name_kana_last_cont`] = props.talent_name;
  const {loading, error, data} = useQuery(FETCH_PROPOSAL_DOCUMENTS_QUERY, {variables: variables});
  if(loading) return "...";
  if(error) return "エラーが発生しました。"+error;

  console.log(data);
  let proposal_documents = data.proposalDocuments.edges;
  let table_data = proposal_documents.map(edge => edge.node);

  const columns = [
    {header: '名前', accessor: 'name', Cell: cell => { return (<Link to={'/proposal_documents/'+cell.row.original.id+'/edit'}>{cell.row.original.name}</Link>) }},
  ];

  const handle_fetch_data = (index, size) => {
    setPage(index);
    setOffset(index*size);
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>過去リスト一覧{' '}</CardHeader>
        <CardBody>
          <PastProposalDocumentPaginationTable
            data={table_data}
            columns={columns}
            page={page}
            total_size={Math.ceil(data.proposalDocuments.totalCount/limit)}
            fetch_data={handle_fetch_data} />

        </CardBody>
      </Card>
    </>
  );
};

export default PastProposalDocumentContainer;
