import React, { useState } from "react";
import { Card, CardBody, Col, Input, Row } from "reactstrap";
import CardHeader from "reactstrap/lib/CardHeader";
import EditCustomPaginationTable from "./edit_custom_pagination_table";
import INFLUENCER_FETCH_TALENTS_QUERY from "../../graphql/queries/influencer_talents_query";
import { useQuery } from "@apollo/react-hooks";

const EditCustomSearch = (props) => {
  const changeCheckedTalentId = props.changeCheckedTalentId;
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  console.log(props.search_params)

  const limit = 1000;
  let variables = {
    q: {
      name_cont: props.search_params.name,
      gender_in: props.search_params.gender,
      influencer_talent_company_id_in: props.search_params.company,
      cost_lt_any: props.search_params.cost_lt,
      cost_gt_any: props.search_params.cost_gt,
      channel_register_count_lt_any: props.search_params.channel_register_count_lt,
      channel_register_count_gt_any: props.search_params.channel_register_count_gt,
      channel_average_play_count_lt_any: props.search_params.channel_average_play_count_lt,
      channel_average_play_count_gt_any: props.search_params.channel_average_play_count_gt,
      channel_expected_play_count_lt_any: props.search_params.channel_expected_play_count_lt,
      channel_expected_play_count_gt_any: props.search_params.channel_expected_play_count_gt,
    },
    limit: 1000,
    offset: offset,
    sort_type: "influencer_talents.name",
    sort_order: "desc",
  };

  if(props.search_params.age) {
    variables['q']['g'] = {}
    variables['q']['g']['c'] = {}
    variables['q']['g']['c']['m'] = 'and'
    variables['q']['g']['c']['g'] = {}
    variables['q']['g']['c']['g'][0] = {}
    variables['q']['g']['c']['g'][1] = {}
    variables['q']['g']['c']['g'][0]['influencer_talent_age_ranges_age_range_id_in'] = props.search_params.age;
    variables['q']['g']['c']['g'][1]['influencer_talent_age_ranges_order_in'] = props.search_params.age.map((v, i) => i + 1 );
  }

  let ratio = 80
  if(props.search_params.gender_ratio === "1") {
    variables['q']['influencer_talent_gender_ratios_man_ratio_gt'] = ratio;
  } else if(props.search_params.gender_ratio === "2") {
    variables['q']['influencer_talent_gender_ratios_woman_ratio_gt'] = ratio;
  }
  
  if(props.search_params.gender && props.search_params.gender.length > 0) {
    variables['q']['g'] = [{
      m: 'or',
      gender_in: props.search_params.gender,
      gender_null: true
    }];
    delete variables['q']['gender_in'];
  }

  const { loading, error, data } = useQuery(INFLUENCER_FETCH_TALENTS_QUERY, {
    variables: variables,
  });
  if (loading) return "...";
  if (error) return "エラーが発生しました。" + error;

  const handleFetchData = (index, size) => {
    setPage(index);
    setOffset(index * size);
  };

  let outputTalents = []

  const onChange = (e) => {
    let checked_talents = JSON.parse(sessionStorage.getItem('checked_talents')) || []
    if(e.target.checked){
      let t = data.influencerTalents.edges.filter(edge => edge.node.id === e.target.value)[0].node
      outputTalents.push(t);
      checked_talents = checked_talents ? checked_talents.concat(outputTalents) : outputTalents;
    }else{
      outputTalents = outputTalents.filter(edge => edge.id !== e.target.value);
      checked_talents = checked_talents ? checked_talents : outputTalents;
      checked_talents.some(function(v, i){
        if (v.id===e.target.value) checked_talents.splice(i,1);
        return null
      });
    }
    sessionStorage.setItem('checked_talents', JSON.stringify(checked_talents));
    changeCheckedTalentId(convertArrayToValue(checked_talents, 'id'));
  };

  const convertArrayToValue = (array, key) => {
    return array.map( (row) => {return [row[key]]} ).join(',')
  };

  const checkedOrUnchecked = (id) => {
    if(sessionStorage.getItem('checked_talents')) {
      return convertArrayToValue(JSON.parse(sessionStorage.getItem('checked_talents')), 'id').includes(id)
    } else {
      return false
    }
  };

  const columns = () => {
    const clms = [
      {
        header: "-",
        accessor: "chechbox",
        Cell: (cell) => {
          return (
            <>
              <Input type={"checkbox"} name={"talents"} value={cell.row.original.id} onChange={onChange} defaultChecked={checkedOrUnchecked(cell.row.original.id)} />
            </>
          );
        },
      },
    ];
    clms.push({
      header: "タレント名",
      accessor: "name",
      Cell: (cell) => {return cell.row.original.name;},});
    clms.push({
      header: "性別",
      accessor: "gender",
      Cell: (cell) => {return <>{cell.row.original.gender}</>;},});
    clms.push({
      header: "事務所名",
      accessor: "influencerTalentCompany.name",
      Cell: (cell) => {return <>{cell.row.original.influencerTalentCompany.homepageUrl ? <a href={cell.row.original.influencerTalentCompany.homepageUrl} target="_blank" rel="noopener noreferrer">{cell.row.original.influencerTalentCompany.name}</a> : cell.row.original.influencerTalentCompany.name }</>;},});
    clms.push({
      header: "Youtube URL",
      accessor: "youtubeUrl",
      Cell: (cell) => {return <><a href={cell.row.original.youtubeUrl} target="_blank" rel="noopener noreferrer">{cell.row.original.youtubeUrl}</a></>;},});
    clms.push({
      header: "金額",
      accessor: "cost",
      Cell: (cell) => {return <>{Number(cell.row.original.cost).toLocaleString()}</>;},});
    clms.push({
      header: "チャンネル登録者数",
      accessor: "channelRegisterCount",
      Cell: (cell) => {return <>{Number(cell.row.original.channelRegisterCount).toLocaleString()}</>;},});
    clms.push({
      header: "平均再生回数",
      accessor: "channelAveragePlayCount",
      Cell: (cell) => {return <>{Number(cell.row.original.channelAveragePlayCount).toLocaleString()}</>;},});
    clms.push({
      header: "想定再生数",
      accessor: "channelExpectedPlayCount",
      Cell: (cell) => {return <>{Number(cell.row.original.channelExpectedPlayCount).toLocaleString()}</>;},});
    return clms;
  };

  return (
    <>
      <Card>
        <CardHeader>検索結果</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <EditCustomPaginationTable
                data={data.influencerTalents.edges.map((edge) => edge.node)}
                columns={columns()}
                page={page}
                total_size={Math.ceil(
                  data.influencerTalents.totalCount / limit
                )}
                fetch_data={handleFetchData}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default EditCustomSearch;
