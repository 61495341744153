import gql from "graphql-tag";

const FETCH_TALENT_SECONDARY_CATEGORY_QUERY = gql`
query talentSecondaryCategories($talent_primary_category_name: String!){
  talentSecondaryCategories(talentPrimaryCategoryName: $talent_primary_category_name){
    id
    name
    createdAt
    updatedAt
  }
}`;

export default FETCH_TALENT_SECONDARY_CATEGORY_QUERY;
