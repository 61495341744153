import React, {useState} from 'react';
import {Button, Card, CardBody, CardFooter, CardHeader, Form} from "reactstrap";
import {useFieldArray, useForm} from "react-hook-form";
import NameForm from "../../components/talents/name_form";
import ImageForm from '../../components/talents/image_form';
import GenderForm from "../../components/talents/gender_form";
import BirthdayForm from "../../components/talents/birthday_form";
import GroupForm from "../../components/talents/group_form";
import GroupKanaForm from "../../components/talents/group_kana_form";
import CategoryPrimaryItemForm from "../../components/talents/category_primary_item_form";
import CategorySecondaryItemForm from "../../components/talents/category_secondary_item_form";
import CategoryTertiaryItemForm from "../../components/talents/category_tertiary_item_form";
import NativePlaceForm from "../../components/talents/native_place_form";
import YearPriceForm from "../../components/talents/year_price_form";
import TwitterAccountForm from "../../components/talents/twitter_account_form";
import InstagramAccountForm from "../../components/talents/instagram_account_form";
import YoutubeAccountForm from "../../components/talents/youtube_account_form";
import BlogAccountForm from "../../components/talents/blog_account_form";
import WeiboAccountForm from "../../components/talents/weibo_account_form";
import CompanyNameForm from "../../components/talents/company_name_form";
import CompanyUrlForm from "../../components/talents/company_url_form";
import ADD_TALENT_QUERY from "../../graphql/mutations/talent_mutation";
import {useMutation} from "@apollo/react-hooks";
import ProfileForm from "../../components/talents/profile_form";
import {useHistory} from 'react-router-dom';

const New = (props) => {
  const [category_primary, set_category_primary] = useState('');
  const [category_secondary, set_category_secondary] = useState('');

  const history = useHistory();

  const [add_talent] = useMutation(ADD_TALENT_QUERY);

  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: {
      name: '',
      gender: '',
      images: [{ url: "", file: "", category: 1 }, { url: "", file: "", category: 1 },{ url: "", file: "", category: 1 }],
      birthday: {year: '', month: '', day: ''},
      categories: {primary: "", secondary: "", tertiary: ""},
      group: '',
      group_kana: '',
      company_name: '',
      company_url: '',
      native_place: '',
      year_price: 0,
      twitter_account: '',
      instagram_account: '',
      youtube_account: '',
      blog_account: '',
      weibo_account: '',
      profile: '',
    }
  });
  const { fields, append } = useFieldArray({control, name: "images"});

  const onSubmit = (data) => {
    let variables = data;
    console.log(data);
    variables['image_categories'] = data.images.map(image => image.category ? parseInt(image.category) : 0)
    variables['image_urls'] = data.images.map(image => image.url ? image.url : "");
    variables['image_files'] = data.images.map(image => image.file ? image.file: "");
    variables['birthday'] = `${data.birthday.year}-${data.birthday.month}-${data.birthday.day}`;
    variables['primary_category'] = `${data.categories.primary}`;
    variables['secondary_category'] = `${data.categories.secondary}`;
    variables['tertiary_category'] = `${data.categories.tertiary}`;
    variables['year_price'] = parseInt(data.year_price);
    console.log(variables);
    add_talent({variables: variables})
      .then(result => {
        alert(`『${result.data.createTalent.talent.name}』を保存しました。`);
        history.push(`/talents/${result.data.createTalent.talent.id}`);
    }).catch(error => {
      console.log(error);
    });
  };

  const handleChangeCategoryPrimary = (e) => {
    set_category_primary(e.target.value);
  };
  const handleChangeCategorySecondary = (e) => {
    set_category_secondary(e.target.value);
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>タレント新規作成{' '}</CardHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardBody>
            <NameForm required register={register} errors={errors} />
            <ImageForm fields={fields} register={register} />
            <Button onClick={() => { append({ url: "", file: "", category: 1 }) }} color={'primary'}>画像を追加</Button>
            <div className={'mb-4'} />
            <GenderForm register={register} />
            <BirthdayForm register={register} />
            <GroupForm register={register} />
            <GroupKanaForm register={register} />
            <CategoryPrimaryItemForm required register={register} errors={errors} onChange={handleChangeCategoryPrimary}/>
            <CategorySecondaryItemForm register={register} onChange={handleChangeCategorySecondary} category_primary={category_primary} />
            <CategoryTertiaryItemForm register={register} category_secondary={category_secondary} />
            <CompanyNameForm register={register} />
            <CompanyUrlForm register={register} />
            <NativePlaceForm register={register} />
            <YearPriceForm register={register} />
            <TwitterAccountForm register={register} />
            <InstagramAccountForm register={register} />
            <YoutubeAccountForm register={register} />
            <BlogAccountForm register={register} />
            <WeiboAccountForm register={register} />
            <ProfileForm register={register} />
          </CardBody>
          <CardFooter className={'text-right'}>
            <Button color="primary" type='submit'>作成</Button>
          </CardFooter>
        </Form>
      </Card>
    </>
  );
};

export default New;
