import React, {useState} from 'react';
import {Button, CardBody, CardFooter, Form} from "reactstrap";
import {useHistory} from "react-router-dom";
import {useMutation} from "react-apollo";
import {useFieldArray, useForm} from "react-hook-form";
import NameForm from "../../components/talents/name_form";
import ImageForm from "../../components/talents/image_form";
import GenderForm from "../../components/talents/gender_form";
import BirthdayForm from "../../components/talents/birthday_form";
import GroupForm from "../../components/talents/group_form";
import GroupKanaForm from "../../components/talents/group_kana_form";
import CategoryPrimaryItemForm from "../../components/talents/category_primary_item_form";
import CategorySecondaryItemForm from "../../components/talents/category_secondary_item_form";
import CategoryTertiaryItemForm from "../../components/talents/category_tertiary_item_form";
import CompanyNameForm from "../../components/talents/company_name_form";
import CompanyUrlForm from "../../components/talents/company_url_form";
import NativePlaceForm from "../../components/talents/native_place_form";
import YearPriceForm from "../../components/talents/year_price_form";
import TwitterAccountForm from "../../components/talents/twitter_account_form";
import InstagramAccountForm from "../../components/talents/instagram_account_form";
import YoutubeAccountForm from "../../components/talents/youtube_account_form";
import BlogAccountForm from "../../components/talents/blog_account_form";
import WeiboAccountForm from "../../components/talents/weibo_account_form";
import UPDATE_TALENT_QUERY from "../../graphql/mutations/update_talent_mutation";
import ProfileForm from "./profile_form";

const TalentEditForm = (props) => {
  const history = useHistory();

  const [category_primary, set_category_primary] = useState('');
  const [category_secondary, set_category_secondary] = useState('');

  const [update_talent] = useMutation(UPDATE_TALENT_QUERY);

  const { register, control, handleSubmit, errors } = useForm({
    defaultValues: {
      images: props.talent_images
    }
  });

  const { fields, append } = useFieldArray({control, name: "images"});

  const onSubmit = (data) => {
    let variables = data;
    if(data.images){
      variables['image_categories'] = data.images?.map(image => image.category ? parseInt(image.category) : 0);
      variables['image_urls'] = data.images?.map(image => image.url ? image.url : "");
      variables['image_files'] = data.images?.map(image => image.file ? image.file : "");
    }else{
      variables['image_categories'] = [];
      variables['image_urls'] = [];
      variables['image_files'] = [];
    }
    variables['birthday'] = `${data.birthday.year}-${data.birthday.month}-${data.birthday.day}`;
    variables['primary_category'] = `${data.categories.primary}`;
    variables['secondary_category'] = `${data.categories.secondary}`;
    variables['tertiary_category'] = `${data.categories.tertiary}`;
    variables['year_price'] = parseInt(data.year_price);
    variables['id'] = parseInt(props.id);

    console.log(variables)

    update_talent({variables: variables})
      .then(result => {
        alert(`『${result.data.updateTalent.talent.name}』を保存しました。`);
        history.push(`/talents/${props.id}`)
      }).catch(error => {
        alert(error);
    });
  };

  const handleChangeOnClick = () => {
    append({ url: "", file: "", category: 1 })
  };

  const handleChangeCategoryPrimary = (e) => {
    set_category_primary(e.target.value);
  };
  const handleChangeCategorySecondary = (e) => {
    set_category_secondary(e.target.value);
  };

  let [year, month, day] = ["", "", ""];
  if(props.talent.birthday) {
    [year, month, day] = props.talent?.birthday?.split('-');
  }

  return (
    <>
       <Form onSubmit={handleSubmit(onSubmit)}>
         <CardBody>
           <NameForm required register={register} errors={errors} control={control} value={props.talent?.name} />
           <ImageForm fields={fields} register={register()} />
           <Button onClick={handleChangeOnClick} color={'primary'}>画像を追加</Button>
           <div className={'mb-4'} />
           <GenderForm register={register} control={control} value={props.talent?.gender} />
           <BirthdayForm register={register} value={{year: parseInt(year), month: parseInt(month), day: parseInt(day)}}/>
           <GroupForm register={register} value={props.talent?.talentGroup?.name} />
           <GroupKanaForm register={register} value={props.talent?.talentGroup?.name_kana} />
           <CategoryPrimaryItemForm required register={register} errors={errors} onChange={handleChangeCategoryPrimary} value={props.talent?.talentPrimaryCategory?.name}/>
           <CategorySecondaryItemForm register={register} onChange={handleChangeCategorySecondary} category_primary={category_primary} value={props.talent?.talentSecondaryCategory?.name} />
           <CategoryTertiaryItemForm register={register} category_secondary={category_secondary} value={props.talent?.talentTertiaryCategory?.name} />
           <CompanyNameForm register={register} value={props.talent?.talentCompany?.name} />
           <CompanyUrlForm register={register} value={props.talent?.companyUrl} />
           <NativePlaceForm register={register} value={props.talent?.nativePlace} />
           <YearPriceForm register={register} value={props.talent?.yearPrice} />
           <TwitterAccountForm register={register} value={props.talent?.twitterAccount} />
           <InstagramAccountForm register={register} value={props.talent?.instagramAccount} />
           <YoutubeAccountForm register={register} value={props.talent?.youtubeAccount} />
           <BlogAccountForm register={register} value={props.talent?.blogUrl} />
           <WeiboAccountForm register={register} value={props.talent?.weiboAccount} />
           <ProfileForm register={register} value={props.talent?.profile} />
         </CardBody>
         <CardFooter className={'text-right'}>
           <Button color="primary" type='submit'>更新</Button>
         </CardFooter>
       </Form>
     </>
   );
};

export default TalentEditForm;
