import gql from "graphql-tag";

const DELETE_DOCUMENT_QUERY = gql`
mutation deleteInfluencerTalentProposalDocument($id: Int!){
  deleteInfluencerTalentProposalDocument(input: {
    id: $id
  }){
    success
  }
}`;

export default DELETE_DOCUMENT_QUERY;
