import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { useMutation, useQuery } from "@apollo/react-hooks";
import FETCH_PROPOSAL_DOCUMENT_QUERY from "../../graphql/queries/influencer_talent_proposal_document_query";
import InfluencerTalentSearchContainer from "../../components/influencer_talents/influencer_talent_search_container";
import InfluencerTalentPool from "../../components/influencer_talent_proposal_documents/influencer_talent_pool";
import ADD_DOCUMENT_ITEM_QUERY from "../../graphql/mutations/influencer_talent_proposal_document_item_mutation";
import DELETE_DOCUMENT_ITEM_QUERY from "../../graphql/mutations/delete_influencer_talent_proposal_document_item_mutation";
import UPDATE_ORDER_DOCUMENT_ITEM_QUERY from "../../graphql/mutations/update_influencer_talent_proposal_document_item_mutation";
import { useForm } from "react-hook-form";
import axios from "axios";
import moment from "moment";

const excel_header_items = [
  [{ header: "金額における諸条件", accessor: "detail_about_cost" }],
  [
    { header: "チャンネル名", accessor: "name" },
    { header: "写真", accessor: "image" },
    { header: "職業", accessor: "job" },
    { header: "生年月日", accessor: "birthday" },
    { header: "年齢", accessor: "age" },
  ],
  [
    { header: "YouTube URL", accessor: "youtube_url" },
    { header: "登録者数", accessor: "channel_register_count" },
    { header: "Twitter URL", accessor: "twitter_url" },
    { header: "フォロワー数", accessor: "twitter_follower_count" },
    { header: "Instagram URL", accessor: "instagram_url" },
    { header: "フォロワー数", accessor: "instagram_follower_count" },
    { header: "プロフィール", accessor: "profile" },
    { header: "視聴者層データ(男女比・年齢層)", accessor: "gender_ratio" },
    { header: "平均再生回数", accessor: "channel_average_play_count" },
    { header: "想定再生回数", accessor: "channel_expected_play_count" },
    { header: "概算金額", accessor: "cost" },
    { header: "備考orオススメコメント", accessor: "recommend_point" },
  ],
];

const Edit = (props) => {
  const id = parseInt(props.match.params.id);
  const [
    influencer_talent_pool_data,
    set_influencer_talent_pool_data,
  ] = useState({});
  const { register, handleSubmit } = useForm({ defaultValues: {} });

  const [add_influencer_talent_pool] = useMutation(ADD_DOCUMENT_ITEM_QUERY);
  const [delete_influencer_talent_pool] = useMutation(
    DELETE_DOCUMENT_ITEM_QUERY
  );
  const [update_influencer_talent_pool] = useMutation(
    UPDATE_ORDER_DOCUMENT_ITEM_QUERY
  );
  const { loading, error, data } = useQuery(FETCH_PROPOSAL_DOCUMENT_QUERY, {
    variables: {
      id: id,
      account_id: parseInt(localStorage.getItem("account_id")),
    },
  });
  if (loading) return "...";
  if (error) return "エラーが発生しています。" + error;

  let influencer_talent_proposal_document =
    data.influencerTalentProposalDocument;
  let influencer_talent_proposal_document_items_on_server = [];
  influencer_talent_proposal_document.influencerTalentProposalDocumentItems.map(
    (influencer_talent_proposal_document_item) =>
      influencer_talent_proposal_document_items_on_server.push(
        influencer_talent_proposal_document_item
      )
  );
  if (Object.keys(influencer_talent_pool_data).length === 0)
    set_influencer_talent_pool_data({
      influencer_talent_proposal_document_items: influencer_talent_proposal_document_items_on_server,
    });

  const handleAddTalentPool = (influencer_talent) => {
    let influencer_talent_order =
      influencer_talent_pool_data.influencer_talent_proposal_document_items
        .length + 1;
    // DBに追加
    add_influencer_talent_pool({
      variables: {
        id: id,
        influencer_talent_id: parseInt(influencer_talent.id),
        influencer_talent_image_id: 0,
        influencer_talent_order: influencer_talent_order,
      },
    })
      .then((result) => {
        let receive_data =
          result.data.createInfluencerTalentProposalDocumentItem
            .influencerTalentProposalDocumentItems;
        console.log(receive_data);
        set_influencer_talent_pool_data({
          influencer_talent_proposal_document_items: receive_data,
        });
        alert(`『${influencer_talent.name}』を追加しました。`);
      })
      .catch((error) => {
        alert("エラーが発生しました。" + error);
      });
  };

  const handleDeleteTalentPool = (influencer_talent) => {
    // DBから削除(名前で検索)
    delete_influencer_talent_pool({
      variables: {
        id: id,
        influencer_talent_id: parseInt(influencer_talent.id),
      },
    })
      .then((result) => {
        let receive_data =
          result.data.deleteInfluencerTalentProposalDocumentItem
            .influencerTalentProposalDocumentItems;
        set_influencer_talent_pool_data({
          influencer_talent_proposal_document_items: receive_data,
        });
        alert(`『${influencer_talent.name}』を削除しました。`);
      })
      .catch((error) => {
        alert("エラーが発生しました。" + error);
      });
  };

  const handlePrevTalentPool = (influencer_talent) => {
    let influencer_talent_proposal_document_items = influencer_talent_pool_data.influencer_talent_proposal_document_items.filter(
      (pdi) =>
        parseInt(pdi.influencerTalentId) === parseInt(influencer_talent.id)
    );
    let influencer_talent_proposal_document_item =
      influencer_talent_proposal_document_items[0];
    let influencer_talent_order = parseInt(
      influencer_talent_proposal_document_item.influencerTalentOrder
    );
    if (influencer_talent_order > 1) {
      update_influencer_talent_pool({
        variables: {
          id: id,
          influencer_talent_id: parseInt(influencer_talent.id),
          influencer_talent_image_id: 0,
          influencer_talent_order: influencer_talent_order - 1,
        },
      })
        .then((result) => {
          let receive_data =
            result.data.updateInfluencerTalentProposalDocumentItem
              .influencerTalentProposalDocumentItems;
          set_influencer_talent_pool_data({
            influencer_talent_proposal_document_items: receive_data,
          });
        })
        .catch((error) => {
          alert("エラーが発生しました。" + error);
        });
    }
  };

  const handleNextTalentPool = (influencer_talent) => {
    let influencer_talent_proposal_document_items = influencer_talent_pool_data.influencer_talent_proposal_document_items.filter(
      (pdi) =>
        parseInt(pdi.influencerTalentId) === parseInt(influencer_talent.id)
    );
    let influencer_talent_proposal_document_item =
      influencer_talent_proposal_document_items[0];
    let influencer_talent_order = parseInt(
      influencer_talent_proposal_document_item.influencerTalentOrder
    );
    if (
      influencer_talent_pool_data.influencer_talent_proposal_document_items
        .length >=
      influencer_talent_order + 1
    ) {
      update_influencer_talent_pool({
        variables: {
          id: id,
          influencer_talent_id: parseInt(influencer_talent.id),
          influencer_talent_image_id: 0,
          influencer_talent_order: influencer_talent_order + 1,
        },
      })
        .then((result) => {
          let receive_data =
            result.data.updateInfluencerTalentProposalDocumentItem
              .influencerTalentProposalDocumentItems;
          set_influencer_talent_pool_data({
            influencer_talent_proposal_document_items: receive_data,
          });
        })
        .catch((error) => {
          alert("エラーが発生しました。" + error);
        });
    }
  };

  const onSubmit = (data) => {
    let params = new URLSearchParams();
    params.append("excel_target_name", data.excel_target_name);
    params.append("excel_project_name", data.excel_project_name);
    Object.keys(data.excel_items).map((key) =>
      params.append(`excel_items[${key}]`, Boolean(data.excel_items[key]))
    );
    axios
      .post(
        `${process.env.REACT_APP_DOCUMENT_ENDPOINT}/influencer_talent_proposal_documents/${id}`,
        params,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let today = moment();
        let today_str = today.format("MMDD");
        link.setAttribute(
          "download",
          `【A3】${today_str}_${data.excel_target_name}様_${data.excel_project_name}ご提案資料.xlsx`
        ); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <>
      <Card>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader>
            <i className="icon-menu" />
            リストダウンロード{" - "}
            {influencer_talent_proposal_document.name}
          </CardHeader>
          <CardBody>
            <FormGroup row>
              <Col md={2}>
                <Label for={"excel_target_name"}>宛先名</Label>
              </Col>
              <Col md={10}>
                <Input
                  type={"text"}
                  id={"excel_target_name"}
                  name={"excel_target_name"}
                  required
                  innerRef={register}
                />
                <p>
                  例)
                  ここに「〇〇」と入力するとエクセルのタイトルが「20200503_〇〇様_□□□_ご提案資料.xlsx」となります。
                </p>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md={2}>
                <Label for={"excel_project_name"}>案件名</Label>
              </Col>
              <Col md={10}>
                <Input
                  type={"text"}
                  id={"excel_project_name"}
                  name={"excel_project_name"}
                  required
                  innerRef={register}
                />
                <p>
                  例)
                  ここに「□□□」と入力するとエクセルのタイトルが「20200503_〇〇様_□□□_ご提案資料.xlsx」となります。
                </p>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Col md={2}>
                <Label>エクセルに出力する項目</Label>
              </Col>
              <Col md={10}>
                {excel_header_items.map((items) => {
                  return items.map((excel_header_item) => {
                    return (
                      <FormGroup check inline>
                        <Label for={excel_header_item.accessor} check>
                          <Input
                            type={"checkbox"}
                            id={excel_header_item.accessor}
                            name={`excel_items[${excel_header_item.accessor}]`}
                            defaultChecked={true}
                            innerRef={register}
                          />
                          {excel_header_item.header}
                          <br />
                          <p style={style.excel_item_explain}>
                            {excel_header_item.explain}
                          </p>
                        </Label>
                      </FormGroup>
                    );
                  });
                })}
              </Col>
            </FormGroup>
          </CardBody>
          <CardFooter className={"text-right"}>
            <Button type={"submit"} color={"primary"}>
              Excel ダウンロード
            </Button>
          </CardFooter>
        </Form>
      </Card>

      <InfluencerTalentPool
        influencer_talent_proposal_document_items={
          influencer_talent_pool_data.influencer_talent_proposal_document_items
        }
        handleDeleteTalentPool={handleDeleteTalentPool}
        handlePrevTalentPool={handlePrevTalentPool}
        handleNextTalentPool={handleNextTalentPool}
      />

      <InfluencerTalentSearchContainer
        isEditPage={true}
        handleAddTalentPool={handleAddTalentPool}
      />
    </>
  );
};

const style = {
  excel_item_explain: {
    fontSize: "12px",
  },
};

export default Edit;
