import gql from "graphql-tag";

const DELETE_DOCUMENT_ITEM_QUERY = gql`
mutation deleteProposalDocumentItem($id: Int!, $talent_id: Int!){
  deleteProposalDocumentItem(input: {
    id: $id
    talentId: $talent_id
  }){
    success
    proposalDocumentItems{
      id
      proposalDocumentId
      talentId
      talentImageId
      talentOrder
      talent{
        id
        name
        gender
        birthday
        createdAt
        updatedAt
        talentPrimaryCategory{
          id
          name
        }
        talentSecondaryCategory{
          id
          name
        }
        talentTertiaryCategory{
          id
          name
        }
        talentCompany{
          id
          name
          createdAt
          updatedAt
        }
        talentGroup{
          id
          name
          nameKana
          createdAt
          updatedAt
        }
        talentNicknames{
          id
          name
          nameKanaFirst
          nameKanaLast
          createdAt
          updatedAt
        }
        talentNotes{
          id
          title
          contents
          createdAt
          updatedAt
        }
        talentImages{
          id
          imageUrl
          imageCategory
          createdAt
          updatedAt
        }
      }
      talentImage{
        id
        imageUrl
        imageCategory
        createdAt
        updatedAt
      }
    }
  }
}`;

export default DELETE_DOCUMENT_ITEM_QUERY;
