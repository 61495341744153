import gql from "graphql-tag";

const ADD_ACCOUNT_QUERY = gql`
mutation createAccount($email: String!, $password: String!, $role: Int!){
  createAccount(input: {
    email: $email
    password: $password
    role: $role
  }){
    success
    account{
      id
    }
  }
}`;

export default ADD_ACCOUNT_QUERY;
