import {Col, FormGroup, Input, Label} from "reactstrap";
import React from "react";

const AgeRangeForm = (props) => {
  return (
    <FormGroup>
      <Label>年齢</Label>
      <FormGroup row>
        <Col>
          <Input type='text' name={'age_range.first'} id={'age_range.first'} innerRef={props.register} />
        </Col>
        歳
        〜
        <Col>
          <Input type='text' name={'age_range.last'} id={'age_range.last'} innerRef={props.register} />
        </Col>
        歳
      </FormGroup>
    </FormGroup>
  );
};

export default AgeRangeForm;
