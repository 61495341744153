import React from "react";
import {Input} from "reactstrap";

const SelectImageType = (props) => {
  console.log(props)

  let talent = props.proposal_document_item.talent;

  const option_str = (image_category) => {
    let option_image_type_str = ""
    switch(image_category){
      case 'bust_up':
        option_image_type_str = 'バストアップ';
        break
      case 'face_up':
        option_image_type_str = '顔アップ'
        break
      case 'sns':
        option_image_type_str = 'SNS'
        break
      default:
        option_image_type_str = 'その他'
    }
    return option_image_type_str;
  };

  let image_type = <option>なし</option>;
  if(talent.talentImages?.length > 0) image_type = talent.talentImages.map(talentImage => <option value={talentImage.id}>{option_str(talentImage.imageCategory)}</option>)

  return (
    <Input type={'select'} defaultValue={props.image_id} onChange={(e) => props.handle_image_select(e)}>
      {image_type}
    </Input>
  )
};

export default SelectImageType;
