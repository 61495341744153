import React, {useState} from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {useQuery} from "@apollo/react-hooks";
import INFLUENCER_FETCH_TALENT_QUERY from "../../graphql/queries/influencer_talents_query";
import InfluencerTalentPaginationTable from "./influencer_talent_pagination_table";

const InfuluencerTalentSearchResult = (props) => {
  const [limit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [sortType] = useState('influencer_talents.name');
  const [sortOrder] = useState('desc');
  const [page, setPage] = useState(0);

  let variables = {
  q: {
      name_cont: props.name,
      influencer_talent_company_name_cont: props.company,
      gender_in: props.gender,
      g: {
      c: {
          m: 'and',
          g: {
          0: {influencer_talent_age_ranges_age_range_id_in: props.ageRange},
          1: {influencer_talent_age_ranges_order_in: props.ageRange.map((v, i) => i + 1 )}
          }
      }
      },
      cost_lt_any: props.costLt,
      cost_gt_any: props.costGt,
      channel_register_count_lt_any: props.channelRegisterCountLt,
      channel_register_count_gt_any: props.channelRegisterCountGt,
  },
  limit: limit,
  offset: offset,
  sort_type: sortType,
  sort_order: sortOrder,
  };

  let ratio = 80
  if(props.genderRatio === "1") {
  variables['q']['influencer_talent_gender_ratios_man_ratio_gt'] = ratio;
  } else if(props.genderRatio === "2") {
  variables['q']['influencer_talent_gender_ratios_woman_ratio_gt'] = ratio;
  }

  if(props.gender && props.gender.length > 0) {
  variables['q']['g'] = [{
      m: 'or',
      gender_in: props.gender,
      gender_null: true
  }];
  delete variables['q']['gender_in'];
  }


  const {loading, error, data} = useQuery(INFLUENCER_FETCH_TALENT_QUERY, {variables: variables});
  if(loading) return "...";
  if(error) return "エラーが発生しました。"+error;

  console.log(data)
  const columns = [
    {header: '名前', accessor: 'name', Cell: cell => { return (<>{cell.row.original.name}</>) }},
    {header: '性別', accessor: 'gender', Cell: cell => { return (<>{cell.row.original.gender}</>) }},
    {header: '所属事務所', accessor: 'company', Cell: cell => { return (<>{cell.row.original.influencerTalentCompany.homepageUrl ? <a href={cell.row.original.influencerTalentCompany.homepageUrl} target="_blank" rel="noopener noreferrer">{cell.row.original.influencerTalentCompany.name}</a> : cell.row.original.influencerTalentCompany.name }</>) }},
    {header: 'YouTube URL', accessor: 'youtubeUrl', Cell: cell => { return (<><a href={cell.row.original.youtubeUrl} target="_blank" rel="noopener noreferrer">{cell.row.original.youtubeUrl ? cell.row.original.youtubeUrl.slice(0,15)+"…" : ''}</a></>) }},
    {header: '金額', accessor: 'cost', Cell: cell => { return (<>{Number(cell.row.original.cost).toLocaleString()}</>) }},
    {header: 'チャンネル登録者数', accessor: 'channelRegisterCount', Cell: cell => { return (<>{Number(cell.row.original.channelRegisterCount).toLocaleString()}</>) }},
    {header: '平均再生回数', accessor: 'channelAveragePlayCount', Cell: cell => { return (<>{Number(cell.row.original.channelAveragePlayCount).toLocaleString()}</>) }},
    {header: '想定再生数', accessor: 'channelExpectedPlayCount', Cell: cell => { return (<>{Number(cell.row.original.channelExpectedPlayCount).toLocaleString()}</>) }},
    {header: '視聴者男女比', accessor: 'genderRatio', Cell: cell => {
      const manRatio = cell.row.original.influencerTalentGenderRatio?.manRatio ? Number(cell.row.original.influencerTalentGenderRatio?.manRatio).toLocaleString() : 0
      const womanRatio = cell.row.original.influencerTalentGenderRatio?.womanRatio ? Number(cell.row.original.influencerTalentGenderRatio?.womanRatio).toLocaleString() : 0
      return (<>{manRatio} : {womanRatio}</>)
    }},
    {header: '視聴者年齢層', accessor: 'ageRanges', Cell: cell => {
      return cell.row.original.influencerTalentAgeRanges?.map((row) => {
        return (
          <>
            {row.ageRange.name + ' ' + row.ratio + '%'}
            <br/>
          </>
        );
      })
    }},
    {header: 'データ取り込み期間', accessor: 'period', Cell: cell => { return (<>{cell.row.original.period}</>) }},
  ];

  if(props.isEditPage){
    columns.push(
      {header: '-', accessor: '-', Cell: cell => { return (
          <>
            <Button color={'primary'} onClick={() => props.handleAddTalentPool(cell.row.original)}>タレントプールに追加する</Button>
          </>
        )}}
    );
  }

  let influencer_talents = data.influencerTalents.edges;
  influencer_talents = influencer_talents.map(edge => edge.node);

  const handleFetchData = (index, size) => {
    setPage(index);
    setOffset(index*size);
  };

  return (
    <>
      <Card>
        <CardHeader><i className="icon-menu"/>検索結果{' '}</CardHeader>
        <CardBody>
          <InfluencerTalentPaginationTable
            data={influencer_talents}
            columns={columns}
            page={page}
            total_size={Math.ceil(data.influencerTalents.totalCount/limit)}
            fetch_data={handleFetchData}
          />
        </CardBody>
      </Card>
    </>
  )
}

export default InfuluencerTalentSearchResult
