import {FormGroup, Label} from "reactstrap";
import React from "react";
import CheckBoxButton from "../shared/checkbox_buttom";

const CostForm = (props) => {
  return (
    <>
      <FormGroup>
        <Label for={"cost"} style={{fontWeight: 800}}>■ 単価（仕入れ）</Label>
      </FormGroup>
      <FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-1`}
            name={"cost"}
            label={"~50万"}
            value={1}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-2`}
            name={"cost"}
            label={"51~100万"}
            value={2}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-3`}
            name={"cost"}
            label={"101~200万"}
            value={3}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-4`}
            name={"cost"}
            label={"201~300万"}
            value={4}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-5`}
            name={"cost"}
            label={"301~400万"}
            value={5}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-6`}
            name={"cost"}
            label={"401~500万"}
            value={6}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
        <FormGroup check inline>
          <CheckBoxButton
            id={`cost-7`}
            name={"cost"}
            label={"501万~"}
            value={7}
            innerRef={props.register}
            colorNone={true}
          />
        </FormGroup>
      </FormGroup>
    </>
  );
};

export default CostForm;
