import gql from "graphql-tag";

const FETCH_PROPOSAL_DOCUMENTS_QUERY = gql`
query influencerTalentProposalDocuments($q: BaseScalar, $limit: Int, $offset: Int) {
  influencerTalentProposalDocuments(q: $q, limit: $limit, offset: $offset){
    totalCount
    edges {
      node {
        id
        name
        createdAt
        updatedAt
        influencerTalentProposalDocumentItems{
          id
          influencerTalentProposalDocumentId
          influencerTalentId
          influencerTalentImageId
          influencerTalentOrder
          influencerTalent{
            id
            name
            gender
            createdAt
            updatedAt
            influencerTalentCompany{
              id
              name
              homepageUrl
              createdAt
              updatedAt
            }
            influencerTalentImages{
              id
              imageUrl
              imageCategory
              createdAt
              updatedAt
            }
          }
          influencerTalentImage{
            id
            imageUrl
            imageCategory
            createdAt
            updatedAt
          }
        }
      }
    }
  }
}`;

export default FETCH_PROPOSAL_DOCUMENTS_QUERY;
