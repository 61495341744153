import {Media} from "reactstrap";
import React from "react";

const RoundedImage = (props) => {
  return (
    <>
      <Media object src={props.image_url} style={style.image} />
    </>
  );
};

const style = {
  image: {
    borderRadius: '50%',
    width: '120px',
    height: '120px'
  },
};

export default RoundedImage;
