import Dashboard from './containers/dashboard';
import ProposalPendingList from './containers/proposal_documents/pending/index';
import ProposalDoneList from './containers/proposal_documents/done/index';
import ProposalDetail from './containers/proposal_documents/show';
import ProposalCreate from './containers/proposal_documents/new';
import ProposalUpdate from './containers/proposal_documents/edit';
import ProposalUpdateCustom from './containers/proposal_documents/edit_custom';
import TalentList from './containers/talents/index';
import TalentDetail from './containers/talents/show';
import TalentCreate from './containers/talents/new';
import TalentUpdate from './containers/talents/edit';
import TalentCSVImport from './containers/talents/csv_import';
import UserList from './containers/users/index';
import UserDetail from './containers/users/show';
import UserCreate from './containers/users/new';
import ProposalPastList from "./containers/proposal_documents/past/index";
import TalentCSVExport from "./containers/talents/csv_export";
import InfluencerTalentProposalPendingList from './containers/influencer_talent_proposal_documents/pending/index';
import InfluencerTalentProposalDoneList from './containers/influencer_talent_proposal_documents/done/index';
import InfluencerTalentProposalDetail from './containers/influencer_talent_proposal_documents/show';
import InfluencerTalentProposalCreate from './containers/influencer_talent_proposal_documents/new';
import InfluencerTalentProposalUpdate from './containers/influencer_talent_proposal_documents/edit';
import InfluencerTalentProposalPastList from "./containers/influencer_talent_proposal_documents/past/index";

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'ダッシュボード', component: Dashboard },
  { path: '/proposal_documents/new', name: 'リスト作成', component: ProposalCreate },
  { path: '/proposal_documents/:id/edit', name: 'リスト更新', component: ProposalUpdate },
  { path: '/proposal_documents/:id/edit_custom', name: 'リスト更新', component: ProposalUpdateCustom },
  { path: '/proposal_documents/past/search', name: '過去リスト', component: ProposalPastList },
  { path: '/proposal_documents/pending/list', name: '作成中リスト', component: ProposalPendingList },
  { path: '/proposal_documents/done/list', name: 'DL済リスト', component: ProposalDoneList },
  { path: '/proposal_documents/:id', name: '詳細', component: ProposalDetail },
  { path: '/talents', name: 'タレント', component: TalentList, exact: true },
  { path: '/talents/list', name: '一覧', component: TalentList },
  { path: '/talents/new', name: '新規作成', component: TalentCreate },
  { path: '/talents/csv_export', name: 'CSVエクスポート', component: TalentCSVExport },
  { path: '/talents/csv_import', name: 'CSVインポート', component: TalentCSVImport },
  { path: '/talents/:id/edit', name: '更新', component: TalentUpdate },
  { path: '/talents/:id', name: '詳細', component: TalentDetail },
  { path: '/users', name: 'ユーザー管理', component: UserList, exact: true },
  { path: '/users/list', name: '一覧', component: UserList },
  { path: '/users/new', name: '新規作成', component: UserCreate },
  { path: '/users/:id', name: '詳細', component: UserDetail },
  { path: '/users/:id/edit', name: '新規作成', component: UserCreate },
  { path: '/influencer_talent_proposal_documents/new', name: 'リスト作成', component: InfluencerTalentProposalCreate },
  { path: '/influencer_talent_proposal_documents/:id/edit', name: 'リスト更新', component: InfluencerTalentProposalUpdate },
  { path: '/influencer_talent_proposal_documents/past/search', name: '過去リスト', component: InfluencerTalentProposalPastList },
  { path: '/influencer_talent_proposal_documents/pending/list', name: '作成中リスト', component: InfluencerTalentProposalPendingList },
  { path: '/influencer_talent_proposal_documents/done/list', name: 'DL済リスト', component: InfluencerTalentProposalDoneList },
  { path: '/influencer_talent_proposal_documents/:id', name: '詳細', component: InfluencerTalentProposalDetail },
];

export default routes;
