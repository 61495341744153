import gql from "graphql-tag";

const UPDATE_TALENT_QUERY = gql`
mutation updateTalent(
  $id: Int!,
  $name: String!,
  $image_categories: [Int!]!,
  $image_urls: [String!]!,
  $image_files: [String!]!,
  $gender: String!,
  $birthday: String!,
  $group: String!,
  $group_kana: String!,
  $primary_category: String!,
  $secondary_category: String!,
  $tertiary_category: String!,
  $company_name: String!,
  $company_url: String!,
  $native_place: String!,
  $year_price: Int!,
  $twitter_account: String!,
  $instagram_account: String!,
  $youtube_account: String!,
  $blog_account: String!,
  $weibo_account: String!
  $profile: String!
){
  updateTalent(input: {
    id: $id
    name: $name
    imageCategories: $image_categories
    imageUrls: $image_urls
    imageFiles: $image_files
    gender: $gender
    birthday: $birthday
    group: $group
    groupKana: $group_kana
    primaryCategory: $primary_category
    secondaryCategory: $secondary_category
    tertiaryCategory: $tertiary_category
    companyName: $company_name
    companyUrl: $company_url
    nativePlace: $native_place
    yearPrice: $year_price
    twitterAccount: $twitter_account
    instagramAccount: $instagram_account
    youtubeAccount: $youtube_account
    blogAccount: $blog_account
    weiboAccount: $weibo_account
    profile: $profile
  }){
      success
      talent{
          id
          name
      }
  }
}`;

export default UPDATE_TALENT_QUERY;
